import {Model, model, modelAction, prop} from 'mobx-keystone'
import {computed} from 'mobx'
import {Contact} from './contact'
import {uniqBy} from 'lodash'
import {BacklinkNodeData} from '..'
import {buildModel, onContactsnapshot} from '../../../services/api'
@model('ContactStore')
export class ContactStore extends Model({
  contacts: prop<Contact[]>(() => []),
}) {
  @computed
  get validContacts(): Contact[] {
    // Filter by contacts with a name
    const contacts = this.contacts.filter((contact) => contact.name)

    return uniqBy(contacts, 'name')
  }

  @computed
  get availableBacklinks(): BacklinkNodeData[] {
    return this.validContacts.map((contact) => ({
      contact,
      photoUrl: contact.photoUrl,
      value: contact.name,
    }))
  }

  findByName(name: string): Contact | undefined {
    return this.validContacts.find((c) => c.name === name)
  }

  findByEmail(email: string): Contact | undefined {
    return this.validContacts.find((c) => c.emailValues.includes(email))
  }

  emailToName(email: string): string | undefined {
    return this.findByEmail(email)?.name
  }

  private listeners: any[] = []

  @modelAction
  setContacts(contacts: Contact[]) {
    this.contacts = contacts
  }

  onAttachedToRootStore() {
    this.listeners.push(
      onContactsnapshot((snapshots) => {
        const contacts = snapshots.map((sn) => buildModel<Contact>(Contact, sn))

        this.setContacts(contacts)
      }),
    )
    return async () => await this.beforeDetach()
  }

  async beforeDetach() {
    for (const unsubscribe of this.listeners) {
      ;(await Promise.resolve(unsubscribe))()
    }
  }
}
