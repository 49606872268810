import React from 'react'
import {ELEMENT_LI, getParent} from '@udecode/slate-plugins'
import clsx from 'classnames'
import {Transforms} from 'slate'
import {ReactEditor, useEditor} from 'slate-react'
import {ExpandableListItemElementProps} from './types'

export const ExpandableUnorderedListElement: React.FC<ExpandableListItemElementProps> = ({
  element,
  attributes,
  children,
}) => {
  const editor = useEditor()

  const toggleParentCollapsed = () => {
    const path = ReactEditor.findPath(editor, element)

    const parentListItemEntry = getParent(editor, path)
    if (!parentListItemEntry) return

    const [parentListItem, parentListItemPath] = parentListItemEntry

    // Are we in a list, or in the root?
    if (parentListItem.type != ELEMENT_LI) return

    Transforms.setNodes(
      editor,
      {collapsed: !parentListItem.collapsed},
      {
        at: parentListItemPath,
      },
    )
  }

  return (
    <ul {...attributes} className={clsx('expandable-unordered-list relative')}>
      <span
        contentEditable={false}
        className="select-none expandable-unordered-list-spine"
      >
        <span
          onClick={() => toggleParentCollapsed()}
          style={{width: 10, left: -17.5, top: 0, bottom: 0}}
          className="absolute cursor-pointer group"
        >
          <span
            style={{width: 1, left: 0, top: 0, bottom: 0}}
            className="absolute bg-gray-100 dark:bg-gray-700 transition duration-500 group-hover:bg-purple-550"
          />
        </span>
      </span>

      {children}
    </ul>
  )
}
