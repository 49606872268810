import {Model, model, modelAction, prop} from 'mobx-keystone'
import {Meeting} from './meeting'
import {buildModel, listMeetings} from '../../../services/api'
import {startOfDay, endOfDay} from 'date-fns'
import {generateDayId} from './helpers'

interface DayMeetings {
  [day: string]: Meeting[]
}

@model('MeetingStore')
export class MeetingStore extends Model({
  dayMeetings: prop<DayMeetings>(() => ({})),
}) {
  meetingsForDay(day: Date): Meeting[] {
    return this.dayMeetings[generateDayId(day)] || []
  }

  async fetchMeetingsForDay(day: Date) {
    const startAt = startOfDay(day)
    const endAt = endOfDay(day)

    const meetings = await listMeetings({startAt, endAt})
    const builtMeetings = meetings.map((m) => buildModel<Meeting>(Meeting, m))

    this.setMeetingsForDay(day, builtMeetings)
  }

  @modelAction
  setMeetingsForDay(day: Date, meetings: Meeting[]) {
    const dayId = generateDayId(day)
    this.dayMeetings[dayId] = meetings
  }
}
