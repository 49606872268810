import {ELEMENT_LI, ELEMENT_UL, findNode} from '@udecode/slate-plugins'
import {Ancestor, Editor, Node, NodeEntry, Transforms, Text, Element, Path} from 'slate'
import {generateList, generateListItem, generateParagraphText} from './generators'
import {getListChild, getListItemText, toNodesArray} from './queries'

export const appendChild = (
  editor: Editor,
  parentEntry: NodeEntry<Ancestor>,
  nodes: Editor | Element | Text | Node[],
): NodeEntry<Node> => {
  const [parentNode, parentPath] = parentEntry
  const at = parentPath.concat([parentNode.children.length])

  Transforms.insertNodes(editor, nodes, {at})

  return Editor.node(editor, at)
}

export const appendToDocument = (
  editor: Editor,
  nodes: Editor | Element | Text | Node[],
): NodeEntry<Node> => {
  const at = Editor.end(editor, [])

  Transforms.insertNodes(editor, nodes, {at})

  return Editor.node(editor, at)
}

const removeNodes = (editor: Editor, at: Path) => {
  try {
    return Transforms.removeNodes(editor, {at})
  } catch (e) {
    return null
  }
}

const listHasBlankListItem = (listNode: Ancestor): boolean => {
  const [firstListItem] = listNode.children

  if (!firstListItem) return false

  const firstListItemChildren: Node[] = (firstListItem.children as Node[]) || []

  if (firstListItemChildren.length > 1) return false

  return Node.string(firstListItem) === ''
}

const appendToList = (
  editor: Editor,
  listEntry: NodeEntry<Ancestor>,
  nodes: Editor | Element | Text | Node[],
) => {
  const [listNode, listPath] = listEntry

  appendChild(editor, listEntry, nodes)

  // The normalizer insert an empty p tag at the top of the list.
  // We need to clean that up now that we've added children
  if (listHasBlankListItem(listNode)) {
    removeNodes(editor, listPath.concat([0]))
  }
}

const appendToListItemList = (
  editor: Editor,
  listItem: NodeEntry<Ancestor>,
  nodes: Editor | Element | Text | Node[],
) => {
  const listChild = getListChild(listItem)

  if (listChild) {
    appendToList(editor, listChild, nodes)
  } else {
    appendChild(editor, listItem, {
      type: ELEMENT_UL,
      children: toNodesArray(nodes),
    }) as NodeEntry<Ancestor>
  }
}

const findNamedListItem = (editor: Editor, listName: string) => {
  return findNode(editor, {
    match: (node) => node.type === ELEMENT_LI && getListItemText(node) === listName,
  }) as NodeEntry<Ancestor>
}

const findList = (editor: Editor) => {
  return findNode(editor, {match: {type: ELEMENT_UL}}) as NodeEntry<Ancestor>
}

const ensureList = (editor: Editor) => {
  if (findList(editor)) return

  appendToDocument(editor, generateList([]))
}

export const appendNodesToList = (editor: Editor, listName: string, nodes: Node[]) => {
  if (!findNamedListItem(editor, listName)) {
    // Create a list if we haven't got one
    ensureList(editor)

    // Append sub-list
    appendToList(
      editor,
      findList(editor),
      generateListItem([...generateParagraphText(listName), ...generateList([])]),
    )
  }

  appendToListItemList(editor, findNamedListItem(editor, listName), nodes)
}

export const syncListItems = (editor: Editor, listName: string, texts: string[]) => {
  const editorText = Node.string(editor)
  const newTexts = texts.filter((text) => !editorText.includes(text))

  for (const newText of newTexts) {
    appendNodesToList(editor, listName, generateListItem(generateParagraphText(newText)))
  }
}
