import {Model, model, modelAction, prop} from 'mobx-keystone'
import {computed} from 'mobx'
import {APIUserTraits, updatePhoto} from '../../../services/api'

@model('User')
export class User extends Model({
  name: prop<string | undefined>(),
  email: prop<string>(),
  photoUrl: prop<string | undefined>(),
  graphIds: prop<string[]>(() => []),
  loaded: prop<boolean>(false),
  gatedAccess: prop<boolean>(true),
  traits: prop<APIUserTraits>(() => ({})),
  stripeSubscriptionId: prop<string | undefined>(),
  stripeSubscriptionProductId: prop<string | undefined>(),
}) {
  get id() {
    return this.$modelId
  }

  @computed
  get givenName(): string | undefined {
    if (!this.name) return
    const [givenName] = this.name.split(/\s/, 2)
    return givenName
  }

  @computed
  get familyName(): string | undefined {
    if (!this.name) return
    const [, familyName] = this.name.split(/\s/, 2)
    return familyName
  }

  async updatePhoto(file: File) {
    const src = URL.createObjectURL(file)
    this.setPhotoUrl(src)
    await updatePhoto(file)
  }

  @modelAction
  setPhotoUrl(url: string) {
    this.photoUrl = url
  }
}
