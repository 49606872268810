export const GRAPHS_COLLECTION = 'graphs'
export const NOTES_COLLECTION = 'notes'
export const LINKS_COLLECTION = 'links'
export const BOOKS_COLLECTION = 'books'
export const USERS_COLLECTION = 'users'

export interface APIAuthUser {
  uid: string
}

export interface APIUserTraits {
  [key: string]: string | number | boolean
}
export interface APIUser {
  id: string
  name: string
  email: string
  photoUrl: string
  graphIds: string[]
  loaded: boolean
  gatedAccess: boolean
  traits: APIUserTraits
  stripeSubscriptionId: string
  stripeSubscriptionProductId: string
}

export interface APIEmail {
  value: string
  primary: boolean
}

export interface APIPhoneNumber {
  value: string
  primary: boolean
}

export interface APIContact {
  id?: string
  givenName?: string
  familyName?: string
  emails?: APIEmail[]
  phoneNumbers?: APIPhoneNumber[]
  photoUrl?: string
}

type BookNoteType = 'note' | 'highlight'

export interface APIBookNote {
  type: BookNoteType
  page: number
  value: string
}
export interface APIBook {
  id: string
  asin: string
  authors: string[]
  publicationDate: Date
  purchaseDate: Date
  title: string
  notes: APIBookNote[]
}

export interface APITag {
  id: string
}

export type Unsubscribe = () => void

export interface APIError {
  type: string
  message?: string
}

export interface APIResponse {
  error?: APIError
}
