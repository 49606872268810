import React from 'react'
import {LinkElementProps} from '@udecode/slate-plugins'
import {openExternal} from '../../..'
import {urlToHref} from '../../../../plugins/url-fns'

const openLink = (url: string) => {
  if (!url) return
  openExternal(url) || window.open(url)
  return false
}

const LinkElement: React.FC<LinkElementProps> = ({children, element}) => {
  const href = element.url && urlToHref(element.url)

  return (
    <a
      className="cursor-pointer text-teal-500"
      href={href}
      title={href}
      onClick={() => openLink(href)}
    >
      {children}
    </a>
  )
}

export {LinkElement}
