import {Link} from './link'
import {onLinksSnapshot, syncLink} from '../../../services/api'
import {getParent, Model, model, modelAction, prop} from 'mobx-keystone'
import {Graph} from '../graph/graph'
import {autorun, computed} from 'mobx'

@model('LinkStore')
export class LinkStore extends Model({
  links: prop<Link[]>(() => []),
}) {
  get graph(): Graph | undefined {
    return getParent<Graph>(this)
  }

  get assertGraph(): Graph {
    const graph = this.graph

    if (!graph) throw new Error('blank graph')

    return graph
  }

  @computed
  get unsyncedLinks() {
    return this.links.filter((link) => link.synced === false)
  }

  @modelAction
  replaceLinks(links: Link[]) {
    this.links = links
  }

  private listeners: any[] = []

  onAttachedToRootStore() {
    const graph = this.assertGraph

    this.listeners.push(
      onLinksSnapshot(graph, (links) => {
        this.replaceLinks(links)
      }),
    )

    this.listeners.push(
      autorun(
        async () => {
          this.unsyncedLinks.forEach((link) => syncLink(link, graph))
        },
        {delay: 3000},
      ),
    )

    return async () => await this.beforeDetach()
  }

  async beforeDetach() {
    console.log(`[${this.graph?.id}]`, 'detaching notes')
    for (const unsubscribe of this.listeners) {
      ;(await Promise.resolve(unsubscribe))()
    }
  }
}
