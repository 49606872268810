import {
  DEFAULTS_LIST,
  getListItemEntry,
  ListOnKeyDownOptions,
} from '@udecode/slate-plugins'
import {Editor, Transforms, Node} from 'slate'
import {StringMap} from './types'

export const onKeyDownExpandableList = (options?: ListOnKeyDownOptions) => (
  event: KeyboardEvent,
  editor: Editor,
) => {
  const {ul, li} = DEFAULTS_LIST
  const listItemTest = (node: Node) => node.type === li.type
  const listTest = (node: Node) => node.type === ul.type

  const shortcuts: StringMap = {
    ArrowLeft: 'collapse',
    ArrowRight: 'expand',
  }

  // Valid keys are:
  // - Ctrl shift command right
  // - Ctrl shift command left
  const command = shortcuts[event.key]
  const validKey = command && event.shiftKey && event.ctrlKey && event.metaKey

  if (!validKey) return

  const listItemResult = getListItemEntry(editor, {}, options)
  if (!listItemResult) return

  const {listItem: listItemEntry} = listItemResult
  const [listItem, listItemPath] = listItemEntry

  event.preventDefault()

  const hasListChild = listItem.children.find(listTest)

  if (hasListChild) {
    Transforms.setNodes(
      editor,
      {
        collapsed: command === 'collapse',
      },
      {
        at: listItemPath,
      },
    )
    return
  }

  const parentListItemEntry = Editor.above(editor, {
    match: listItemTest,
    at: listItemPath,
  })

  if (!parentListItemEntry) return

  const [, parentListItemPath] = parentListItemEntry

  Transforms.setNodes(
    editor,
    {
      collapsed: command === 'collapse',
    },
    {
      at: parentListItemPath,
    },
  )
}
