import {Model, model, prop} from 'mobx-keystone'
import {revokeCredential} from '../../../services/api'
@model('Credential')
export class Credential extends Model({
  provider: prop<string>(),
  email: prop<string>(),
  contactsCount: prop<number | undefined>(),
}) {
  get id() {
    return this.$modelId
  }

  revoke() {
    revokeCredential(this.id)
  }
}
