import clsx from 'classnames'
import {TagElementStyleProps, TagElementStyles} from '../types'

export const getTagElementStyles = ({
  className,
  selected,
}: TagElementStyleProps): TagElementStyles => {
  return {
    root: [
      {},
      clsx(className, 'text-purple-550 cursor-pointer rounded-md', {
        'shadow-outline-purple': selected,
      }),
    ],
  }
}
