import {getAuthHeaders} from './helpers'

export const enrich = async ({email, domain}: {email?: string; domain?: string}) => {
  const headers = await getAuthHeaders()
  const url = new URL('/api/enrich', location.href)

  if (email) url.searchParams.append('email', email)
  if (domain) url.searchParams.append('domain', domain)

  return (await fetch(url.href, {headers})).json()
}
