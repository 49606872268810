import {setDefaults} from '@udecode/slate-plugins'
import {SlatePlugin} from '@udecode/slate-plugins-core'
import {DEFAULTS_BACKLINK} from './defaults'
import {deserializeBacklink} from './deserialize-backlink'
import {renderElementBacklink} from './render-element-backlink'
import {BacklinkPluginOptions} from './types'

/**
 * Enables support for autocompleting @backlinks and #tags.
 * When typing a configurable marker, such as @ or #, a select
 * component appears with autocompleted suggestions.
 */
export const BacklinkPlugin = (options?: BacklinkPluginOptions): SlatePlugin => {
  const {backlink} = setDefaults(options, DEFAULTS_BACKLINK)

  return {
    renderElement: renderElementBacklink(options),
    deserialize: deserializeBacklink(options),
    inlineTypes: [backlink.type],
    voidTypes: [backlink.type],
  }
}
