import {DocumentSnapshot} from './document-snapshot'
import {getAuthUser, getIdToken, getAuthUserDoc} from './helpers'
import {Credential} from '../../app/models'

export const getAuthGoogleCredentialsUrl = async () => {
  const authUser = await getAuthUser()
  return location.origin + `/api/credentials/auth/google?uid=${authUser.uid}`
}

export const revokeCredential = async (credentialId: string) => {
  const idToken = await getIdToken()

  return fetch(`/api/credentials/${credentialId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Firebase ${idToken}`,
    },
  })
}

const convertCredential = (doc: DocumentSnapshot): Credential => {
  const data = doc.data()!

  return new Credential({
    $modelId: doc.id,
    provider: data.provider,
    email: data.email,
    contactsCount: data.contacts_count,
  })
}

export const onCredentialsSnapshot = async (
  callback: (credentials: Credential[]) => void,
) => {
  const doc = await getAuthUserDoc()
  const credentialsDoc = doc.collection('credentials')

  return credentialsDoc.onSnapshot((snapshot) => {
    callback(snapshot.docs.map(convertCredential))
  })
}
