import {DocumentSnapshot} from './document-snapshot'
import {getAuthUserDoc} from './helpers'
import * as Types from './types'

export const onContactsnapshot = async (
  callback: (contacts: Types.APIContact[]) => void,
) => {
  const doc = await getAuthUserDoc()
  const contactsDoc = doc.collection('contacts')

  return contactsDoc.onSnapshot((snapshot) => {
    callback(snapshot.docs.map(convertContact))
  })
}

const convertContact = (doc: DocumentSnapshot): Types.APIContact => {
  const data = doc.data()!

  return {
    id: doc.id,
    givenName: data.given_name,
    familyName: data.family_name,
    emails: data.emails || [],
    phoneNumbers: data.phone_numbers || [],
    photoUrl: data.photo_url,
  }
}
