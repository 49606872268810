import {DEFAULTS_LIST, SlateDocument} from '@udecode/slate-plugins'
import {Editor, Node, Transforms} from 'slate'
import {createEditor} from '../../../components/rich-text/editor'
import {generateHeader, generateList} from '../../../components/rich-text/generators'
import {BacklinkNode} from '../../../components/rich-text/plugins/backlink'
import {syncListItems} from '../../../components/rich-text/transforms'
import {BookNote} from '../book/book-note'
import {Contact} from '../contact/contact'
import {generateListFromContact, generateMeetingListItem} from './content-generators'
import {getParagraphRangesByMatchingText} from './content-queries'
import {Note} from './note'
import {SuggestedBacklink} from './suggested-backlink'

export const addSuggestedBacklinkToNodes = (
  nodes: Node[],
  backlink: SuggestedBacklink,
) => {
  const editor = createEditor()
  editor.children = nodes

  const backlinkNode: BacklinkNode = {
    type: 'backlink',
    value: backlink.value,
    noteId: backlink.toNoteId,
    children: [{text: ''}],
  }

  const ranges = getParagraphRangesByMatchingText(editor, backlink.value)

  ranges.forEach((range) => {
    Transforms.insertNodes(editor, backlinkNode, {at: range.current!})
  })

  return editor.children
}

export const mergeContent = (fromContent: Node[], toContent: Node[]): Node[] => {
  if (!toContent.length) return fromContent
  if (!fromContent.length) return toContent

  const toContentRoot = toContent[0] as any
  const fromContentRoot = fromContent[0] as any

  const fromContentChildren = fromContentRoot.children.slice()

  // Remove subject h1
  if (fromContentChildren[0]?.type === 'h1') fromContentChildren.shift()

  return [
    {
      ...toContentRoot,
      children: [...toContentRoot.children, ...fromContentChildren],
    },
  ]
}

export const addSubjectToContent = (subject: string, content: Node[]): SlateDocument => {
  if (!content.length) return [{children: generateHeader(subject)}]

  const contentRoot = content[0] as any

  return [
    {
      ...contentRoot,
      children: [...generateHeader(subject), ...contentRoot.children],
    },
  ]
}

export const rewriteBacklinks = (
  nodes: Node[],
  fromNoteId: string,
  toNoteId: string,
): Node[] => {
  const editor = createEditor()
  editor.children = nodes

  Transforms.setNodes(
    editor,
    {noteId: toNoteId},
    {
      match: (n) => n.type == 'backlink' && n.noteId === fromNoteId,
      at: [],
    },
  )

  return editor.children
}

export const addContactToContent = (contact: Contact, nodes: Node[]): Node[] => {
  const {ul} = DEFAULTS_LIST
  const [metaList] = generateListFromContact(contact)
  const editor = createEditor()
  editor.children = nodes

  const [firstListEntry] = Editor.nodes(editor, {
    mode: 'highest',
    at: [],
    match: (node) => node.type === ul.type,
  })

  if (!firstListEntry) {
    Transforms.insertNodes(editor, metaList)
  } else {
    const [, firstListPath] = firstListEntry

    Transforms.insertNodes(editor, metaList.children as Node[], {
      at: firstListPath.concat([0]),
    })
  }

  return editor.children
}

export const addMeetingToContent = ({
  content,
  meetingNote,
  attendeeNotes,
}: {
  content: Node[]
  meetingNote?: Note
  attendeeNotes: Note[]
}): Node[] => {
  const {ul} = DEFAULTS_LIST

  const listItem = generateMeetingListItem({
    meetingNote,
    attendeeNotes,
  })

  const editor = createEditor()
  editor.children = content

  const [firstListEntry] = Editor.nodes(editor, {
    mode: 'highest',
    at: [],
    match: (node) => node.type === ul.type,
  })

  if (!firstListEntry) {
    Transforms.insertNodes(editor, generateList([listItem]))
  } else {
    const [, firstListPath] = firstListEntry

    Transforms.insertNodes(editor, listItem, {
      at: firstListPath.concat([0]),
    })
  }

  return editor.children
}

export const syncLinkHighlightsToContent = (
  content: SlateDocument,
  highlights: string[],
): SlateDocument => {
  const editor = createEditor()
  editor.children = content

  syncListItems(editor, 'Highlights', highlights)

  return editor.children as SlateDocument
}

export const syncBookNotesToContent = (
  content: SlateDocument,
  notes: BookNote[],
): SlateDocument => {
  const editor = createEditor()
  editor.children = content

  const formattedHighlight = notes
    .filter((note) => note.type === 'highlight')
    .map((note) => [note.value, note.page ? `(p${note.page})` : null].join(' '))

  const formattedNotes = notes
    .filter((note) => note.type === 'note')
    .map((note) => [note.value, note.page ? `(p${note.page})` : null].join(' '))

  syncListItems(editor, 'Highlights', formattedHighlight)
  syncListItems(editor, 'Notes', formattedNotes)

  return editor.children as SlateDocument
}
