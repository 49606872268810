import {
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_TODO_LI,
  setDefaults,
} from '@udecode/slate-plugins'
import escapeHtml from 'escape-html'
import {Node, Text} from 'slate'
import {ELEMENT_BACKLINK} from '../../plugins/backlink'
import {ELEMENT_TAG} from '../../plugins/tags'

const serializeHTMLFromNode = (node: Node, options?: Record<string, any>): string => {
  const {image, link, backlink, tag, todo_li, linkHost, stripLinks} = setDefaults(
    options,
    {
      image: {type: ELEMENT_IMAGE},
      link: {type: ELEMENT_LINK},
      backlink: {type: ELEMENT_BACKLINK},
      tag: {type: ELEMENT_TAG},
      todo_li: {type: ELEMENT_TODO_LI},
      linkHost: '',
      stripLinks: false,
    },
  )

  if (Text.isText(node)) {
    return escapeHtml(node.text)
  }

  const children = node.children
    .map((child) => serializeHTMLFromNode(child, options))
    .join('')

  switch (node.type) {
    case link.type:
      if (stripLinks) return children
      return `<a href="${escapeHtml(node.url as string)}">${children}</a>`
    case image.type:
      return `<img href="${escapeHtml(node.src as string)} />`
    case todo_li.type:
      return `${children}`
    case tag.type:
      return `#${node.value}`
    case backlink.type:
      if (stripLinks) return node.value as string
      return `<a href="https://${linkHost}/g/${node.graphId}/${node.noteId}">${node.value}</a>`
    default:
      return node.type ? `<${node.type}>${children}</${node.type}>` : children
  }
}

export const serializeHTMLFromNodes = (
  nodes: Node[],
  options?: Record<string, any>,
): string => {
  return nodes.map((node) => serializeHTMLFromNode(node, options)).join('')
}
