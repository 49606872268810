import {BacklinkElement} from './components/BacklinkElement'
import {BacklinkKeyOption, BacklinkPluginOptionsValues} from './types'

export const ELEMENT_BACKLINK = 'backlink'

export const DEFAULTS_BACKLINK: Record<BacklinkKeyOption, BacklinkPluginOptionsValues> = {
  backlink: {
    component: BacklinkElement,
    type: ELEMENT_BACKLINK,
    rootProps: {
      className: 'slate-backlink',
    },
  },
}
