import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/remote-config'

const firebaseAuthJson = process.env.NEXT_PUBLIC_FIREBASE_AUTH_JSON

if (!firebaseAuthJson) {
  throw new Error('missing NEXT_PUBLIC_FIREBASE_AUTH_JSON env var')
}

const firebaseAuth = JSON.parse(firebaseAuthJson)
const authEmulatorHost = process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST
const firestoreEmulatorHost = process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST

export const config = {
  ...firebaseAuth,

  // Custom config
  clientID: '188725225641-n0tkosc6bsjc5lponcr9sjec1ifn38b2.apps.googleusercontent.com',

  appleScopes: ['email', 'name'],

  googleScopes: ['email', 'profile'],
}

if (!firebase.apps[0]) {
  firebase.initializeApp(config)
}

// Should we enable things like analytics/remote config which require a browser env
export const browser = typeof window != 'undefined' && !!window.indexedDB

export const auth = firebase.auth()
export const db = firebase.firestore()
export const storage = firebase.storage()
export const analytics = browser ? firebase.analytics() : null
export const remoteConfig = browser ? firebase.remoteConfig() : null
export type {User as AuthUser} from '@firebase/auth-types'

// Setup DB

if (process.env.NODE_ENV == 'test') {
  if (!authEmulatorHost || authEmulatorHost == '') {
    throw new Error('Must use auth emulator for tests')
  }

  if (!firestoreEmulatorHost || firestoreEmulatorHost == '') {
    throw new Error('Must use firestore emulator for tests')
  }
}

if (authEmulatorHost && authEmulatorHost != '') {
  auth.useEmulator(`http://${authEmulatorHost}`)
}

if (firestoreEmulatorHost && firestoreEmulatorHost != '') {
  const [host, port] = firestoreEmulatorHost.split(':')
  db.useEmulator(host, Number(port))
}

// Only enable persistence client-side
if (browser) {
  db.settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  })

  db.enablePersistence({synchronizeTabs: true})
}
