import {ImagePluginOptions, insertImage, isImageUrl} from '@udecode/slate-plugins'
import {Editor, Transforms} from 'slate'
import {ReactEditor} from 'slate-react'

const cacheImage = (url: string) => {
  return new Promise((success) => {
    const img = new Image()
    img.onload = success
    img.src = url
  })
}

const insertAndUploadImage = async (
  editor: Editor,
  file: File,
  callback: {(file: File): Promise<string>},
  options?: ImagePluginOptions<'type'>,
) => {
  const localUrl = URL.createObjectURL(file)

  insertImage(editor, localUrl, options)

  const remoteUrl = await callback(file)

  await cacheImage(remoteUrl)

  Transforms.setNodes(editor, {url: remoteUrl}, {match: (n) => n.url === localUrl})
}

/**
 * Allows for pasting images from clipboard.
 * Not yet: dragging and dropping images, selecting them through a file system dialog.
 * @param img.type
 */
export const withFileUpload = (
  callback: (file: File) => Promise<string>,
  options?: ImagePluginOptions<'type'>,
) => <T extends ReactEditor>(editor: T) => {
  const {insertData} = editor

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain')
    const {files} = data
    if (files && files.length > 0) {
      for (const file of files) {
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          insertAndUploadImage(editor, file, callback, options)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text, options)
    } else {
      insertData(data)
    }
  }

  return editor
}
