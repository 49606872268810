import {
  ELEMENT_H1,
  ELEMENT_LI,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  SlateDocumentFragment,
} from '@udecode/slate-plugins'
import {Node} from 'slate'
import {ELEMENT_BACKLINK} from './plugins/backlink'
import {ELEMENT_TAG} from './plugins/tags'

export const generateHeader = (text = '', type = ELEMENT_H1): SlateDocumentFragment => [
  {
    type: type,
    children: [{text: text || ''}],
  },
]

export const generateParagraph = (children: Node[]): Node[] => {
  return [
    {
      type: ELEMENT_PARAGRAPH,
      children: children,
    },
  ]
}

export const generateParagraphText = (text: string): Node[] => {
  const leaf = {text}

  return generateParagraph([leaf])
}

export const generateBacklink = (text: string, noteId: string): Node[] => [
  {
    type: ELEMENT_BACKLINK,
    value: text,
    noteId,
    children: [{text: ''}],
  },
]

export const generateTag = (value: string): Node[] => [
  {
    type: ELEMENT_TAG,
    value,
    children: [{text: ''}],
  },
]

export const generateLink = (url: string, text: string, mark?: string): Node[] => {
  const leaf: any = {text}

  if (mark) {
    leaf[mark] = true
  }

  return [
    {
      type: ELEMENT_LINK,
      url: url,
      children: [leaf],
    },
  ]
}

export const generateListItem = (children: Node[]): Node[] => {
  if (!children.length) {
    throw new Error('generateListItem requires children')
  }

  return [
    {
      type: ELEMENT_LI,
      children,
    },
  ]
}

export const generateList = (items: Node[][]): Node[] => {
  const itemsOrDefault = items.length ? items : [generateParagraphText('')]
  const children = itemsOrDefault.map((item) => generateListItem(item)).flat()

  return [
    {
      type: ELEMENT_UL,
      children,
    },
  ]
}
