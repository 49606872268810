import {getAuthHeaders} from './helpers'
import {Meeting} from './meetings.types'

export const listMeetings = async ({
  startAt,
  endAt,
}: {
  startAt: Date
  endAt: Date
}): Promise<Meeting[]> => {
  const url = new URL('/api/meetings', location.href)

  url.searchParams.append('start_at', startAt.toISOString())
  url.searchParams.append('end_at', endAt.toISOString())

  const headers = await getAuthHeaders()
  const response = await fetch(url.href, {headers})

  if (response.status === 200) {
    return response.json()
  } else {
    throw new Error(`Invalid response: ${await response.text()}`)
  }
}
