import {setDefaults, getNodeDeserializer} from '@udecode/slate-plugins'
import {DeserializeHtml} from '@udecode/slate-plugins-core'
import {DEFAULTS_BACKLINK} from './defaults'
import {BacklinkDeserializeOptions} from './types'

export const deserializeBacklink = (
  options?: BacklinkDeserializeOptions,
): DeserializeHtml => {
  const {backlink} = setDefaults(options, DEFAULTS_BACKLINK)

  return {
    element: getNodeDeserializer({
      type: backlink.type,
      node: (el) => ({
        type: backlink.type,
        value: el.getAttribute('data-slate-value'),
      }),
      rules: [{className: backlink.rootProps.className}],
    }),
  }
}
