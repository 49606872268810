import {Editor, Range, Node, Ancestor} from 'slate'
import {ReactEditor} from 'slate-react'
import {serializeHTMLFromNodes} from '../../serializers'

const isValidSelection = (editor: ReactEditor) => {
  const {selection} = editor

  if (!selection) return false

  const [start] = Range.edges(selection)
  const startVoid = Editor.void(editor, {at: start.path})

  if (Range.isCollapsed(selection) && !startVoid) {
    return false
  }

  return true
}

const getSelectedNodes = (editor: ReactEditor) => {
  const {selection} = editor
  const result: Node[] = []

  if (!selection) return result

  const nodeEntries = Editor.nodes(editor, {
    at: [],
    mode: 'all',
  })

  for (const [node, path] of nodeEntries) {
    if (Range.includes(selection, path)) {
      result.push(node)
    }
  }

  return result
}

const getMatchingTree = (node: Node, selectedNodes: Node[]): Node => {
  if (node.children) {
    const children = node.children as Node[]
    const filtered = children
      .filter((child) => selectedNodes.includes(child))
      .map((child) => getMatchingTree(child, selectedNodes))

    return {
      ...node,
      children: filtered,
    }
  } else {
    return node
  }
}

const getSelectedTree = (editor: ReactEditor) => {
  const nodes = getSelectedNodes(editor)
  return getMatchingTree(editor, nodes)
}

/**
 * Enables support for serializing content from Slate format to Markdown format.
 */
export const withHTMLCopy = (options?: Record<string, any>) => <T extends ReactEditor>(
  editor: T,
) => {
  const {setFragmentData} = editor

  editor.setFragmentData = (data: DataTransfer) => {
    setFragmentData(data)

    if (!isValidSelection(editor)) return

    const tree = getSelectedTree(editor) as Ancestor
    const html = serializeHTMLFromNodes(tree.children, options)

    data.setData('text/html', html)
  }

  return editor
}
