import {withHistory} from 'slate-history'
import {withReact} from 'slate-react'
import {
  ParagraphPlugin,
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
  HeadingPlugin,
  ImagePlugin,
  LinkPlugin,
  StrikethroughPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  TodoListPlugin,
  ResetBlockTypePlugin,
  SoftBreakPlugin,
  ExitBreakPlugin,
  withMarks,
  withTrailingNode,
  CodePlugin,
  CodeBlockPlugin,
  withInlineVoid,
  withNormalizeTypes,
  withAutoformat,
  withList,
  ListPlugin,
} from '@udecode/slate-plugins'
import {BlockquotePlugin} from './plugins/blockquote'
import {autoformatRules} from './autoformat-rules'
import {
  options,
  optionsBreakList,
  optionsExitBreak,
  optionsFileUploadCallback,
  optionsResetBlockTypes,
  optionsSoftBreak,
} from './options'
import {BacklinkPlugin} from './plugins/backlink'
import {withFileUpload} from './plugins/file-upload/with-file-upload'
import {BreakListPlugin} from './plugins/break-list/break-list'
import {ExpandableListPlugin} from './plugins/expandable-list'
import {withMarkdownCopy} from './plugins/markdown-copy/with-markdown-copy'
import {withHTMLCopy} from './plugins/html-copy/with-html-copy'
import {withDeserializeHTML} from './plugins/deserialize-html/with-deserialize-html'
import {TagPlugin} from './plugins/tags'
import {withLink} from './plugins/link/with-link'

export const plugins = [
  ParagraphPlugin(options),
  BoldPlugin(options),
  ItalicPlugin(options),
  UnderlinePlugin(options),
  CodeBlockPlugin(options),
  BlockquotePlugin(options),
  CodePlugin(options),
  HeadingPlugin(options),
  ImagePlugin(options),
  LinkPlugin(options),
  ListPlugin(options),
  ExpandableListPlugin(options),
  StrikethroughPlugin(options),
  SubscriptPlugin(options),
  SuperscriptPlugin(options),
  TodoListPlugin(options),
  BreakListPlugin(optionsBreakList),
  ResetBlockTypePlugin(optionsResetBlockTypes),
  SoftBreakPlugin(optionsSoftBreak),
  ExitBreakPlugin(optionsExitBreak),
  BacklinkPlugin(options),
  TagPlugin(options),
]

export const withPlugins = [
  withReact,
  withHistory,
  withList(options),
  withLink(options),
  withMarks(),
  withFileUpload(optionsFileUploadCallback),
  withAutoformat({
    rules: autoformatRules,
  }),
  withNormalizeTypes({
    rules: [{path: [0, 0], strictType: options.h1.type}],
  }),
  withTrailingNode({type: options.p.type}),
  withInlineVoid({plugins}),
  withMarkdownCopy(),
  withHTMLCopy(options),
  withDeserializeHTML({plugins}),
  // withDeserializeMd({ plugins }),
] as const
