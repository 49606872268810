import {
  getRootStore,
  Model,
  model,
  modelAction,
  modelFlow,
  prop,
  _async,
  _await,
} from 'mobx-keystone'
import {NoteStore} from '../note/note-store'
import {
  ApiGraph,
  createTag,
  onGraphSnapshot,
  onTagsSnapshot,
  setupGraph,
  updateGraph,
} from '../../../services/api'
import {TagCallback, TagNodeData} from '../tag/types'
import {autorun, computed} from 'mobx'
import {RootStore} from '../store/root-store'
import {decrypt, encrypt} from 'kiss-crypto'
import {passwordToEncryptionKey} from './encryption'
import {LinkStore} from '../link/link-store'
import {User} from '../user/user'
import {BookStore} from '../book/book-store'

@model('Graph')
export class Graph extends Model({
  name: prop(''),
  noteStore: prop<NoteStore | undefined>(),
  linkStore: prop<LinkStore>(() => new LinkStore({})),
  bookStore: prop<BookStore>(() => new BookStore({})),
  acl: prop<string[]>(() => []),
  tags: prop<string[]>(() => []),
  hasSetup: prop(true),
  encryptionKey: prop<string | null>(null),
  encryptionCheck: prop<string | null>(null),
}) {
  get id() {
    return this.$modelId
  }

  @computed
  get loaded() {
    return !!(this.uid && this.acl.length)
  }

  @computed
  get encrypted() {
    return !!this.encryptionCheck
  }

  @computed
  get needsEncryptionSetup() {
    return this.encrypted && !this.encryptionKey
  }

  get assertNoteStore(): NoteStore {
    if (!this.noteStore) throw new Error('blank noteStore')
    return this.noteStore
  }

  aclCanRead(uid: string | undefined) {
    if (!uid) return false

    return (
      this.aclCanWrite(uid) ||
      this.acl.includes('public') ||
      this.acl.includes(`read.${uid}`)
    )
  }

  aclCanWrite(uid: string | undefined) {
    if (!uid) return false

    return this.aclIsOwner(uid) || this.acl.includes(`write.${uid}`)
  }

  aclIsOwner(uid: string | undefined) {
    if (!uid) return false

    return this.acl.includes(`owner.${uid}`)
  }

  async isValidPassword(password: string) {
    if (!password) return false
    if (!this.encryptionCheck) throw new Error('blank encryptionCheck')

    const key = await passwordToEncryptionKey(password)

    let result: string | null

    try {
      result = await decrypt({
        key,
        ciphertext: this.encryptionCheck,
      })
    } catch (error) {
      console.error(error)
      return false
    }

    return result === this.id
  }

  @computed
  get user(): User | undefined | null {
    const rootStore = getRootStore<RootStore>(this)
    return rootStore?.currentUser
  }

  get assertUser(): User {
    const user = this.user
    if (!user) throw new Error('blank user')
    return user
  }

  @computed
  get uid(): string | undefined {
    const rootStore = getRootStore<RootStore>(this)
    return rootStore?.uid
  }

  get assertUid(): string {
    const uid = this.uid
    if (!uid) throw new Error('blank uid')
    return uid
  }

  @computed
  get canRead() {
    return this.aclCanRead(this.uid)
  }

  @computed
  get canWrite() {
    return this.aclCanWrite(this.uid)
  }

  @computed
  get isOwner() {
    return this.aclIsOwner(this.uid)
  }

  @computed
  get needsSetup() {
    return !this.hasSetup && this.isOwner && this.noteStore
  }

  filteredTags(query: string) {
    return this.tags.filter((tag) => tag.includes(query))
  }

  get availableTags(): TagNodeData[] {
    return this.tags.map((value) => ({value}))
  }

  // Actions

  @modelFlow
  setPassword = _async(function* (this: Graph, password: string) {
    this.encryptionKey = yield* _await(passwordToEncryptionKey(password))
    yield* _await(this.updateEncryptionCheck())
  })

  @modelFlow
  updateEncryptionCheck = _async(function* (this: Graph) {
    if (!this.encryptionKey) throw new Error('blank encryptionKey')

    this.encryptionCheck = yield* _await(
      encrypt({key: this.encryptionKey, plaintext: this.id}),
    )

    yield* _await(updateGraph(this))
  })

  async changePassword(newPassword: string) {
    if (!this.noteStore) throw new Error('missing noteStore')

    await this.setPassword(newPassword)
    await this.noteStore.reEncryptNotes()
  }

  @modelAction
  setTags(tags: string[]) {
    this.tags = tags
  }

  @modelAction
  setName(name: string) {
    this.name = name
  }

  update() {
    return updateGraph(this)
  }

  @modelAction
  findOrCreateTag(value: string) {
    return this.tags.find((tag) => tag === value) || this.createTag(value)
  }

  @modelAction
  createTag(value: string) {
    this.tags = this.tags.concat([value])
    createTag(this.id, value)
    return value
  }

  onAddTag(tagNode: TagNodeData): TagCallback {
    const tag = this.createTag(tagNode.value)

    return {
      value: tag,
    }
  }

  @modelAction
  setGraph(graph: ApiGraph) {
    this.name = graph.name
    this.acl = graph.acl
    this.hasSetup = graph.hasSetup
    this.encryptionCheck = graph.encryptionCheck
  }

  @modelAction
  initNoteStore() {
    this.noteStore = new NoteStore({})
  }

  private listeners: any[] = []

  onAttachedToRootStore() {
    console.log(`[${this.id}]`, 'Attached graph to root store')

    this.listeners.push(
      autorun(() => {
        // Only instantiate NoteStore once we have
        // an encryption key

        if (this.loaded && !this.needsEncryptionSetup && !this.noteStore) {
          this.initNoteStore()
        }
      }),
    )

    const unsubscribeSetup = autorun(() => {
      if (this.needsSetup) {
        setupGraph(this)
        unsubscribeSetup()
      }
    })

    this.listeners.push(unsubscribeSetup)

    this.listeners.push(onGraphSnapshot(this.id, (snapshot) => this.setGraph(snapshot)))

    this.listeners.push(onTagsSnapshot(this.id, (snapshot) => this.setTags(snapshot)))

    return async () => await this.beforeDetach()
  }

  async beforeDetach() {
    for (const unsubscribe of this.listeners) {
      ;(await Promise.resolve(unsubscribe))()
    }
  }
}
