import {
  ELEMENT_LI,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  SlateDocument,
} from '@udecode/slate-plugins'
import {Ancestor, Editor, Node, NodeEntry, Path, Element, Text} from 'slate'

export const getDocumentChildren = (document: SlateDocument) => {
  const root = document[0]
  return root.children
}

export const findNodeEntry = (
  nodes: Node[],
  callback: (n: Node) => boolean,
  path: Path = [],
): NodeEntry | undefined => {
  for (const [index, node] of nodes.entries()) {
    const nodePath: Path = [...path, index]

    if (callback(node)) {
      return [node, nodePath]
    }

    if (node.children) {
      const childNode = findNodeEntry(node.children as Node[], callback, nodePath)
      if (childNode) return childNode
    }
  }
}

export const findNode = (
  nodes: Node[],
  callback: (n: Node) => boolean,
): Node | undefined => {
  const [node] = findNodeEntry(nodes, callback) || []
  return node
}

export const findPath = (
  nodes: Node[],
  callback: (n: Node) => boolean,
  path: Path = [],
): Path | undefined => {
  const [, entry] = findNodeEntry(nodes, callback, path) || []
  return entry
}

export const findNodeByType = (nodes: Node[], type: string): Node | undefined => {
  return findNode(nodes, (node) => node.type === type)
}

export const findListNode = (nodes: Node[]) => findNodeByType(nodes, ELEMENT_UL)

export const getListItemText = (node: Node) => {
  if (node.type !== ELEMENT_LI) throw new Error(`Node type ${node.type} is not list-item`)

  const children = node.children as Node[]
  const [pNode] = children

  if (pNode?.type !== ELEMENT_PARAGRAPH) return null

  return Node.string(pNode)
}

export const getLastChildPath = (nodeEntry: NodeEntry<Ancestor>): Path => {
  const [node, path] = nodeEntry

  return path.concat([node.children.length - 1])
}

export const isBlankNode = (node: Node) => {
  const children = node.children as Node[]

  if (!children) return true

  const firstChild = children[0]

  // if children equal []
  if (children.length === 0) return true

  // if children equal [{text: ''}]
  if (children.length === 1 && firstChild.text === '') return true

  // If any of the children conform to the above
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (children.length === 1 && isBlankNode(firstChild)) return true

  return false
}

export const getListChild = (
  parentEntry: NodeEntry<Ancestor>,
): NodeEntry<Ancestor> | undefined => {
  const [parentNode, parentPath] = parentEntry

  const listIndex = parentNode.children.findIndex((node) => node.type === ELEMENT_UL)

  if (listIndex === -1) return

  return [parentNode.children[listIndex] as Ancestor, parentPath.concat([listIndex])]
}

export const toNodesArray = (nodes: Editor | Element | Text | Node[]): Node[] => {
  if (Array.isArray(nodes)) return nodes
  return [nodes]
}
