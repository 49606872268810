import phoneRegex from 'phone-regex-ts'

const PHONE_RE = phoneRegex({exact: true})
const EMAIL_RE = /.+@.+/
const HTTP_RE = /^http:\/\//
const HTTPS_RE = /^https:\/\//

export type UrlType = 'email' | 'phone' | 'http' | 'https'

export const urlToHref = (url: string): string => {
  if (!url) return ''

  switch (urlType(url)) {
    case 'email':
      return `mailto:${url}`
    case 'phone':
      return `tel:${url}`
    case 'http':
    case 'https':
      // Already has http(s) prefix
      return url
    default:
      // Force to be a URL
      return `http://${url}`
  }
}

export const urlType = (url: string): UrlType | null => {
  if (EMAIL_RE.test(url)) {
    return 'email'
  } else if (PHONE_RE.test(url)) {
    return 'phone'
  } else if (HTTPS_RE.test(url)) {
    return 'https'
  } else if (HTTP_RE.test(url)) {
    return 'http'
  } else {
    return null
  }
}

export const isPhoneUrl = (url: string) => PHONE_RE.test(url)
export const isEmailUrl = (url: string) => EMAIL_RE.test(url)
export const isHttpUrl = (url: string) => HTTP_RE.test(url)
export const isHttpsUrl = (url: string) => HTTPS_RE.test(url)
export const isWebUrl = (url: string) => isHttpUrl(url) || isHttpsUrl(url)

export const isWebOrUnknownUrl = (url: string) => {
  const type = urlType(url)

  // Unknown
  if (!type) return true

  return type === 'http' || type === 'https'
}

export const parseDomain = (url: string) => {
  // URL lib requires a protocol
  const protocolUrl = /:\/\//.test(url) ? url : `http://${url}`

  try {
    const url = new URL(protocolUrl)

    // We have a path in the URL
    if (/^\/.+/.test(url.pathname)) return

    const domain = url.hostname?.replace('www.', '')

    return domain
  } catch (e) {
    return null
  }
}
