import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_PARAGRAPH,
  SlateDocument,
  SlateDocumentFragment,
} from '@udecode/slate-plugins'
import {flatten} from 'lodash'
import {Node} from 'slate'
import {
  generateBacklink,
  generateHeader,
  generateLink,
  generateList,
  generateListItem,
  generateParagraph,
  generateParagraphText,
  generateTag,
} from '../../../components/rich-text/generators'
import {deserialize} from '../../../components/rich-text/serializers/serialize-markdown/deserialize-stripped-markdown-to-nodes'
import {Meeting, RoamNote, RoamNoteString} from '../../../services/api'
import {Book} from '../book/book'
import {Contact} from '../contact/contact'
import {Link} from '../link/link'
import {Note} from './note'

export const PERSON_TAG = 'person'
export const BOOK_TAG = 'book'
export const LINK_TAG = 'link'

export const generateListFromContact = (contact: Contact | null): Node[] => {
  return generateList([
    generateParagraphText('Title:: '),
    generateParagraphText('Company:: '),
    generateParagraph([{text: 'Type:: '}, ...generateTag(PERSON_TAG)]),
    [
      ...generateParagraphText('Emails'),
      ...generateList(
        contact?.emailValues.map((email) =>
          generateParagraph(generateLink(email, email)),
        ) || [],
      ),
    ],
    [
      ...generateParagraphText('Phone numbers'),
      ...generateList(
        contact?.phoneNumberValues.map((number) =>
          generateParagraph(generateLink(number, number)),
        ) || [],
      ),
    ],
  ])
}

export const generateMeetingListItem = ({
  meetingNote,
  attendeeNotes,
}: {
  meetingNote?: Note
  attendeeNotes: Note[]
}): Node[] => {
  const attendeeBacklinks = flatten(
    attendeeNotes.map((note) => generateBacklink(note.subject, note.id)),
  )

  const attendeeNodes: Node[] = [{text: 'Met with '}, ...attendeeBacklinks]

  let meetingNodes: Node[] = []

  if (meetingNote) {
    meetingNodes = [
      {text: ' for '},
      ...generateBacklink(meetingNote.subject, meetingNote.id),
    ]
  }

  return generateListItem([
    ...generateParagraph([...attendeeNodes, ...meetingNodes, {text: ''}]),
    ...generateList([generateParagraphText('')]),
  ])
}

export const generateNoteWithList = (subject: string): SlateDocument => {
  const children = [
    ...generateHeader(subject),
    ...generateList([]),
  ] as SlateDocumentFragment

  return [{children}]
}

export const generateContactContent = (contact: Contact | null): SlateDocument => {
  const children = [
    ...generateHeader(contact?.name),
    ...generateListFromContact(contact),
  ] as SlateDocumentFragment

  return [{children}]
}

export const generateBookContent = (book: Book): Node[] => [
  {
    children: [
      ...generateHeader(book.title),
      ...generateList([
        generateParagraph([{text: 'Type:: '}, ...generateTag(BOOK_TAG)]),
        generateParagraphText(`ASIN:: ${book.asin}`),
        generateParagraphText(`Authors:: ${book.authors?.join(', ')}`),
        [
          ...generateParagraphText('Highlights'),
          ...generateList(
            book.notes
              .filter((n) => n.type == 'highlight')
              .map((note) =>
                generateParagraphText(
                  [note.value, note.page ? `(p${note.page})` : null].join(' '),
                ),
              ),
          ),
        ],
        [
          ...generateParagraphText('Notes'),
          ...generateList(
            book.notes
              .filter((n) => n.type == 'note')
              .map((note) =>
                generateParagraphText(
                  [note.value, note.page ? `(p${note.page})` : null].join(' '),
                ),
              ),
          ),
        ],
      ]),
    ],
  },
]

// Format Roam's peculiar backlinks:
// - Replace #[[tag]] with [[tag]]
// - Replace #tag with [[tag]]
const convertRoamTagsToBacklinks = (str: string) => {
  return str.replace(/(^|\s)#\[\[/g, '$1[[').replace(/(^|\s)#(\w+)/g, '$1[[$2]]')
}

const LIST_CHILD_TYPES = [ELEMENT_PARAGRAPH, ELEMENT_BLOCKQUOTE]

export const generateListFromRoamNoteString = (
  roamNoteString: RoamNoteString,
): Node[] => {
  const children = roamNoteString.children || []
  const string = roamNoteString.string?.trim() || ''

  let paragraphNodes: Node[] = generateParagraphText('')

  if (string) {
    paragraphNodes = deserialize({})(convertRoamTagsToBacklinks(string))
  }

  if (!LIST_CHILD_TYPES.includes(paragraphNodes[0]?.type as string)) {
    console.error(roamNoteString)
    throw new Error('Invalid format')
  }

  let childLists: Node[] = []

  if (children.length) {
    childLists = generateList(
      children.map((child) => generateListFromRoamNoteString(child)),
    )
  }

  return [...paragraphNodes, ...childLists]
}

export const generateListFromRoamNote = (roamNote: RoamNote): Node[] => {
  const children = roamNote.children || []

  return generateList(children.map((child) => generateListFromRoamNoteString(child)))
}

export const generateContentFromRoamNote = (roamNote: RoamNote): SlateDocument => {
  const children = [
    ...generateHeader(roamNote.title),
    ...generateListFromRoamNote(roamNote),
  ] as SlateDocumentFragment

  return [{children}]
}

const generateLinkList = (link: Link) => {
  return generateList([
    generateParagraph([{text: 'URL:: '}, ...generateLink(link.url, link.url)]),
    generateParagraphText(`Description:: ${link.description}`),
    generateParagraph([{text: 'Type:: '}, ...generateTag(LINK_TAG)]),
  ])
}

export const generateLinkContent = (link: Link): SlateDocument => {
  const children = [
    ...generateHeader(link.title),
    ...generateLinkList(link),
  ] as SlateDocumentFragment

  return [{children}]
}
