import {
  isCollapsed,
  isPointAtWordEnd,
  getRangeBefore,
  getText,
} from '@udecode/slate-plugins'
import {useCallback, useState} from 'react'
import {Editor, Range, Transforms} from 'slate'
import {insertBacklink} from './transforms'
import {BacklinkNodeData, UseBacklinkOptions} from './types'
import {getNextIndex, getPreviousIndex} from './utils'

export const useBacklink = (
  mentionables: BacklinkNodeData[] = [],
  {maxSuggestions = 7, onAddBacklink, ...options}: UseBacklinkOptions = {},
) => {
  const [target, setTarget] = useState<Range | null>(null)
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [targetText, setTargetText] = useState('')

  const values = mentionables
    .filter((c) => c.value.toLowerCase().includes(search.toLowerCase()))
    .slice(0, maxSuggestions)

  const addBacklink = useCallback(
    (editor: Editor, backlink: BacklinkNodeData) => {
      if (target !== null) {
        const hydratedBacklink = onAddBacklink?.(backlink) || backlink
        Transforms.select(editor, target)
        insertBacklink(editor, hydratedBacklink, options)
        setTarget(null)
      }
    },
    [options, target],
  )

  const onKeyDown = useCallback(
    (event: any, editor: Editor) => {
      if (target) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            setIndex(getNextIndex(index, values.length))
            break
          case 'ArrowUp':
            event.preventDefault()
            setIndex(getPreviousIndex(index, values.length))
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            addBacklink(editor, values[index] || {value: search})
            break
          case ' ':
            if (/]]$/.test(targetText)) {
              addBacklink(editor, values[index] || {value: search})
            }
            break
        }
      }
    },
    [values, index, setIndex, target, setTarget, addBacklink],
  )

  const onChange = useCallback(
    (editor: Editor) => {
      const {selection} = editor

      // No cursor
      if (!selection || !isCollapsed(selection)) {
        return setTarget(null)
      }

      const cursor = Range.start(selection)

      // Cursor is half-way through a word
      if (!isPointAtWordEnd(editor, {at: cursor})) {
        return setTarget(null)
      }

      const range = getRangeBefore(editor, cursor, {
        matchString: '[[',
        skipInvalid: true,
      })

      if (!range) {
        return setTarget(null)
      }

      const rangeText = getText(editor, range)
      const newSearch = rangeText.replaceAll(/[[\]]/g, '')

      setTarget(range)
      setTargetText(rangeText)
      setSearch(newSearch)
      setIndex(0)
    },
    [setTarget, setSearch, setIndex],
  )

  return {
    search,
    index,
    target,
    values,
    onChange,
    onKeyDown,
    addBacklink,
  }
}
