import {
  DEFAULTS_LINK,
  DEFAULTS_LIST,
  DEFAULTS_PARAGRAPH,
  ELEMENT_LI,
  ELEMENT_PARAGRAPH,
  SlateDocument,
} from '@udecode/slate-plugins'
import {Editor, Node, Range, Location, Ancestor, RangeRef} from 'slate'
import {Backlink} from '..'
import {
  serializeHTMLFromNodes,
  serializeTextFromNodes,
} from '../../../components/rich-text/serializers'
import {snakeCase} from 'lodash'
import {DEFAULTS_TAG} from '../../../components/rich-text/plugins/tags'
import {ELEMENT_BACKLINK} from '../../../components/rich-text/plugins/backlink'
import {
  isEmailUrl,
  isPhoneUrl,
  isWebOrUnknownUrl,
  parseDomain,
} from '../../../plugins/url-fns'

const contentChildren = (content: SlateDocument | Node[]): Node[] => {
  const root = content[0]
  if (!root) return []

  return root.children as Node[]
}

export const contentChildrenWithoutSubject = (
  content: SlateDocument | Node[],
): Node[] => {
  const children = contentChildren(content).slice()
  children.shift()
  return children
}

export const contentLine = (content: SlateDocument | Node[]): string => {
  return serializeTextFromNodes(contentChildrenWithoutSubject(content))
}

export const contentSubject = (content: SlateDocument) => {
  const firstChild = contentChildren(content)[0]
  return firstChild ? serializeTextFromNodes([firstChild]) : ''
}

export const contentEmails = (content: SlateDocument) => {
  const {link} = DEFAULTS_LINK

  const findEmails = (node: any, result: string[] = []) => {
    if (node.type === link.type && isEmailUrl(node.url)) {
      result.push(node.url.toLowerCase())
    }

    for (const child of node.children || []) {
      findEmails(child, result)
    }

    return result
  }

  const [root] = content
  const results = root ? findEmails(root) : []
  const uniqResults = [...new Set(results)]

  return uniqResults
}

export const contentPhoneNumbers = (content: SlateDocument) => {
  const {link} = DEFAULTS_LINK

  const findPhoneNumbers = (node: any, result: string[] = []) => {
    if (node.type === link.type && isPhoneUrl(node.url)) {
      result.push(node.url as string)
    }

    for (const child of node.children || []) {
      findPhoneNumbers(child, result)
    }

    return result
  }

  const [root] = content
  const results = root ? findPhoneNumbers(root) : []
  const uniqResults = [...new Set(results)]

  return uniqResults
}

export const contentDomains = (content: SlateDocument) => {
  const {link} = DEFAULTS_LINK

  const findDomains = (node: any, result: string[] = []) => {
    if (node.type === link.type && isWebOrUnknownUrl(node.url)) {
      const domain = parseDomain(node.url)
      if (domain) result.push(domain as string)
    }

    for (const child of node.children || []) {
      findDomains(child, result)
    }

    return result
  }

  const [root] = content
  const results = root ? findDomains(root) : []
  const uniqResults = [...new Set(results)]

  return uniqResults
}

export const contentTags = (content: SlateDocument) => {
  const {tag} = DEFAULTS_TAG

  const findTags = (node: Node, result: string[] = []) => {
    if (node.type === tag.type) {
      if (node.value) result.push(node.value as string)
    }

    const children: Node[] = (node.children as Node[]) || []

    for (const child of children) {
      findTags(child, result)
    }

    return result
  }

  const [root] = content
  const results = root ? findTags(root) : []
  const uniqResults = [...new Set(results)]

  return uniqResults
}

export const contentMetadata = (content: SlateDocument) => {
  const findMetadata = (node: Node, results: any = {}) => {
    const children = (node.children || []) as Node[]

    if (node.type === ELEMENT_LI) {
      const textChildren = children.filter((child) => child.type === ELEMENT_PARAGRAPH)

      const text = serializeTextFromNodes(textChildren)

      if (text.includes('::')) {
        const [textKey, textValue] = text.split('::', 2)
        const key = snakeCase(textKey)
        const value = textValue?.trim()

        if (key && value) {
          results[key] = value
        }
      }
    }

    for (const child of children) {
      findMetadata(child, results)
    }

    return results
  }

  const [root] = content
  return root ? findMetadata(root) : new Map()
}

export const getNodesByIncludesText = (editor: Editor, text: string) => {
  return [
    ...Editor.nodes(editor, {
      mode: 'highest',
      match: (n) => {
        const nodeText = n.text as string | undefined
        return !!nodeText && nodeText.includes(text)
      },
      at: [],
    }),
  ]
}

export const getMatchingParentNode = (
  editor: Editor,
  at: Location,
  match: (arg0: Ancestor) => any,
) => {
  let node: Node
  let path = at

  // eslint-disable-next-line no-cond-assign
  while (([node, path] = Editor.parent(editor, path))) {
    if (match(node)) return node
    if (path.length === 0) break
  }
}

export const getParentListItem = (editor: Editor, at: Location) => {
  const {li} = DEFAULTS_LIST
  return getMatchingParentNode(editor, at, (n) => n.type === li.type)
}

export const getNodesByMatchingText = (editor: Editor, text: string) => {
  const lowerText = text.toLowerCase()

  return [
    ...Editor.nodes(editor, {
      mode: 'highest',
      match: (n) => {
        const nodeText = n.text as string | undefined

        return !!nodeText && nodeText.toLowerCase().includes(lowerText)
      },
      at: [],
    }),
  ]
}

export const getParagraphRangesByMatchingText = (
  editor: Editor,
  text: string,
): RangeRef[] => {
  const nodeEntries = getNodesByMatchingText(editor, text)
  const {p} = DEFAULTS_PARAGRAPH

  return nodeEntries.reduce((result, nodeEntry) => {
    const [node, entry] = nodeEntry
    const nodeText = node.text as string
    const offset = nodeText.toLowerCase().indexOf(text.toLowerCase())
    const [parent] = Editor.parent(editor, entry)

    if (parent.type != p.type) return result

    const range: Range = {
      anchor: {
        path: entry,
        offset: offset,
      },

      focus: {
        path: entry,
        offset: offset + text.length,
      },
    }

    result.push(Editor.rangeRef(editor, range))

    return result
  }, [] as RangeRef[])
}

export const getBacklinks = (content: Node[], fromNoteId: string): Backlink[] => {
  interface IBacklink {
    backlinkNode: Node
    parentListItemNode: Node
  }

  const findBacklinkNodes = (
    nodes: Node[],
    parentListItemNode: Node | undefined = undefined,
    results: IBacklink[] = [],
  ) => {
    for (const node of nodes) {
      let listItemNode: Node = parentListItemNode!

      if (node.type === ELEMENT_BACKLINK) {
        results.push({
          backlinkNode: node,
          parentListItemNode: parentListItemNode!,
        })
      } else if (node.type === ELEMENT_LI) {
        listItemNode = node
      }

      if (node.children) {
        findBacklinkNodes(node.children as Node[], listItemNode, results)
      }
    }

    return results
  }

  const backlinkNodes = findBacklinkNodes(content)

  return backlinkNodes.map((node) => {
    const {parentListItemNode, backlinkNode} = node

    let contextHtml = ''

    if (parentListItemNode) {
      contextHtml = serializeHTMLFromNodes(parentListItemNode.children as Node[], {
        stripLinks: true,
      })
    }

    return {
      contextHtml,
      value: backlinkNode.value as string,
      fromNoteId,
      toNoteId: backlinkNode.noteId as string,
    }
  })
}
