import {
  getParent,
  Model,
  model,
  modelAction,
  prop,
  prop_dateTimestamp,
} from 'mobx-keystone'
import {Graph} from '../graph/graph'
import {LinkStore} from './link-store'

@model('Link')
export class Link extends Model({
  url: prop<string>(),
  title: prop<string>(() => ''),
  description: prop<string | null>(() => ''),
  synced: prop(true),
  updatedAt: prop_dateTimestamp(() => new Date()),
  highlights: prop<string[]>(() => []),
}) {
  get id() {
    return this.$modelId
  }

  get linkStore(): LinkStore | undefined {
    const links = getParent<Link[]>(this)
    return links && getParent<LinkStore>(links)
  }

  get graph(): Graph | undefined {
    return this.linkStore?.graph
  }

  @modelAction
  setSynced(value: boolean) {
    this.synced = value
  }
}
