import {SlateDocument} from '@udecode/slate-plugins'
import {getSnapshot} from 'mobx-keystone'
import {Book, Graph, Note} from '../../app/models'
import {generateBookContent} from '../../app/models/note/content-generators'
import {syncBookNotesToContent} from '../../app/models/note/content-transforms'
import {generateId} from '../../plugins/generate-id'
import {db} from '../firebase'
import {DocumentSnapshot} from './document-snapshot'
import {BOOKS_COLLECTION, GRAPHS_COLLECTION, Unsubscribe} from './types'

export const onBooksSnapshot = async (
  graph: Graph,
  callback: (books: Book[]) => void,
): Promise<Unsubscribe> => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  const booksDoc = graphDoc.collection(BOOKS_COLLECTION)

  return booksDoc.onSnapshot((snapshot) => {
    callback(snapshot.docs.map(convertBook))
  })
}

export const syncBook = async (book: Book, graph: Graph) => {
  const noteStore = graph.noteStore

  // We need notes setup to continue
  if (!noteStore) return

  console.log(`[${graph.id}] syncing book`, book)

  book.setSynced(true)

  try {
    await obtainSyncLock(book, graph)
  } catch (error) {
    console.error(error)
    return
  }

  const note =
    noteStore.findByAsin(book.asin) ||
    new Note({
      $modelId: generateId(),
      subject: book.title,
      content: generateBookContent(book),
    })

  if (!note.noteStore) {
    noteStore.addNote(note)
  }

  const content = getSnapshot(note.content) as SlateDocument

  const syncedContent = syncBookNotesToContent(content, book.notes)

  note.updateContent(syncedContent, {
    rerender: true,
  })
}

const obtainSyncLock = async (book: Book, graph: Graph) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  const bookDoc = graphDoc.collection(BOOKS_COLLECTION).doc(book.id)

  return db.runTransaction(async (trans) => {
    const snapshot = await trans.get(bookDoc)

    if (!snapshot.exists) {
      throw new Error(`unknown snapshot: ${book.id}`)
    } else if (snapshot.data()!.synced) {
      return Promise.reject('already synced')
    } else {
      return trans.set(
        bookDoc,
        {
          synced: true,
        },
        {
          merge: true,
        },
      )
    }
  })
}

const convertBook = (doc: DocumentSnapshot): Book => {
  const data = doc.data()!

  return new Book({
    $modelId: doc.id,
    asin: data.asin,
    title: data.title,
    authors: data.authors,
    publicationDate: data.publication_date,
    purchaseDate: data.purchase_date,
    notes: data.notes,
    synced: data.synced ?? false,
  })
}
