import {getBlockAbove} from '@udecode/slate-plugins'
import {Editor, Range, Node, Path} from 'slate'
import {ReactEditor} from 'slate-react'
import {serializeMarkdownFromNodes} from '../../serializers'

type SelectedText = string | undefined
type SelectedNode = [Node, SelectedText]

const isValidSelection = (editor: ReactEditor) => {
  const {selection} = editor

  if (!selection) return false

  const [start] = Range.edges(selection)
  const startVoid = Editor.void(editor, {at: start.path})

  if (Range.isCollapsed(selection) && !startVoid) {
    return false
  }

  return true
}

const getSelectedText = (node: Node, path: Path, selection: Range) => {
  let text = node.text as string
  if (!text) return

  const [start, end] = Range.edges(selection)

  if (Path.equals(path, end.path)) {
    text = text.slice(0, end.offset)
  }

  if (Path.equals(path, start.path)) {
    text = text.slice(start.offset)
  }

  return text
}

const getSelectedNodes = (editor: ReactEditor): SelectedNode[] => {
  const {selection} = editor
  const result: SelectedNode[] = []

  const nodeEntries = Editor.nodes(editor, {
    at: [],
    mode: 'all',
  })

  for (const [node, path] of nodeEntries) {
    if (Range.includes(selection!, path)) {
      const text = getSelectedText(node, path, selection!)

      result.push([node, text])
    }
  }

  return result
}

const getMatchingTree = (node: Node, selectedNodes: SelectedNode[]) => {
  const selectedNode = selectedNodes.find(([sn]) => sn === node)
  if (!selectedNode) return

  const [, text] = selectedNode

  const result: Node = Object.assign({}, node)

  if (node.children) {
    const ogChildren = node.children as Node[]
    const children = ogChildren
      .map((child) => getMatchingTree(child, selectedNodes))
      .filter((child) => child)

    Object.assign(result, {children})
  }

  if (typeof text === 'string') {
    Object.assign(result, {text})
  }

  return result
}

const getSelectedTree = (editor: ReactEditor) => {
  const nodes = getSelectedNodes(editor)
  return getMatchingTree(editor, nodes)
}

const sameBlockSelection = (editor: ReactEditor) => {
  const {selection} = editor

  const anchorBlock = getBlockAbove(editor, {at: selection!.anchor})
  const focusBlock = getBlockAbove(editor, {at: selection!.focus})

  if (!anchorBlock || !focusBlock) return false

  const [, anchorBlockPath] = anchorBlock
  const [, focusBlockPath] = focusBlock

  return Path.equals(anchorBlockPath, focusBlockPath)
}

const getMarkdownFromSelection = (editor: ReactEditor) => {
  const {selection} = editor

  if (sameBlockSelection(editor)) {
    return Editor.string(editor, selection!)
  } else {
    const tree = getSelectedTree(editor) as ReactEditor
    return serializeMarkdownFromNodes(tree.children as Node[])
  }
}

/**
 * Enables support for serializing content from Slate format to Markdown format.
 */
export const withMarkdownCopy = () => <T extends ReactEditor>(editor: T) => {
  const {setFragmentData} = editor

  editor.setFragmentData = (data: DataTransfer) => {
    setFragmentData(data)

    if (!isValidSelection(editor)) return

    const markdown = getMarkdownFromSelection(editor)

    data.setData('text/markdown', markdown)
    data.setData('text/plain', markdown)
  }

  return editor
}
