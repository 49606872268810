import {
  DEFAULTS_LIST,
  DEFAULTS_PARAGRAPH,
  isCollapsed,
  someNode,
} from '@udecode/slate-plugins'
import isHotkey from 'is-hotkey'
import {Editor, Path, Transforms} from 'slate'
import {BreakListPluginOptions} from './types'

const insertList = (editor: Editor) => {
  const {ul, li} = DEFAULTS_LIST
  const {p} = DEFAULTS_PARAGRAPH

  Transforms.insertNodes(editor, {
    type: ul.type,
    children: [
      {
        type: li.type,
        children: [
          {
            type: p.type,
            children: [
              {
                text: '',
              },
            ],
          },
        ],
      },
    ],
  })
}

const insertListItem = (editor: Editor, listPath: Path) => {
  const {li} = DEFAULTS_LIST
  const {p} = DEFAULTS_PARAGRAPH

  Transforms.insertNodes(
    editor,
    {
      type: li.type,
      children: [
        {
          type: p.type,
          children: [
            {
              text: '',
            },
          ],
        },
      ],
    },
    {
      at: listPath.concat([0]),
      select: true,
    },
  )
}

const selectOrInsertList = (editor: Editor) => {
  const {ul} = DEFAULTS_LIST

  const [listEntry] = Editor.nodes(editor, {
    match: (node) => node.type == ul.type,
    at: [],
    mode: 'highest',
  })

  if (listEntry) {
    const [, listEntryPath] = listEntry
    insertListItem(editor, listEntryPath)
  } else {
    insertList(editor)
  }
}

export const onKeyDownBreakList = ({rules}: BreakListPluginOptions) => (
  event: KeyboardEvent | null,
  editor: Editor,
) => {
  if (editor.selection && isCollapsed(editor.selection)) {
    rules.forEach(({types, hotkey}) => {
      if (!event || (hotkey && isHotkey(hotkey, event))) {
        if (someNode(editor, {match: {type: types}})) {
          event?.preventDefault()

          selectOrInsertList(editor)
        }
      }
    })
  }
}
