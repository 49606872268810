import {deserializeHTMLToDocumentFragment} from '@udecode/slate-plugins'
import {SlatePlugin} from '@udecode/slate-plugins-core'
import {Transforms} from 'slate'
import {ReactEditor} from 'slate-react'

export interface WithDeserializeHTMLOptions {
  plugins?: SlatePlugin[]
}

/**
 * Enables support for deserializing inserted content from HTML format to Slate format.
 */
export const withDeserializeHTML = ({plugins = []}: WithDeserializeHTMLOptions = {}) => <
  T extends ReactEditor
>(
  editor: T,
) => {
  const {insertData} = editor

  editor.insertData = (data: DataTransfer) => {
    const html = data.getData('text/html')

    if (isValidHtml(html)) {
      const {body} = new DOMParser().parseFromString(html, 'text/html')
      const fragment = deserializeHTMLToDocumentFragment({
        plugins,
        element: body,
      })

      Transforms.insertNodes(editor, fragment)
      return
    }

    insertData(data)
  }

  return editor
}

const isValidHtml = (html: string | undefined) => {
  if (!html) return false
  // We don't do well with Google sheets or tables
  if (html.includes('<google-sheets-html-origin>')) return false
  if (html.includes('<table>')) return false
  return true
}
