import {ELEMENT_PARAGRAPH} from '@udecode/slate-plugins'
import {Editor} from 'slate'
import {createEditor} from '../../../components/rich-text/editor'
import {serializeHTMLFromNodes} from '../../../components/rich-text/serializers'
import {getNodesByMatchingText, getParentListItem} from './content-queries'
import {Note} from './note'
export interface SuggestedBacklink {
  fromNoteId: string
  toNoteId?: string
  value: string
  contextHtml?: string
}

export const suggestedIncomingBacklinks = (
  fromNote: Note,
  toNote: Note,
): SuggestedBacklink[] => {
  const text = toNote.subject

  if (!text || text.length <= 3) return []

  if (!fromNote.contentJson.includes(text)) {
    // Short circuit early (this is expensive)
    return []
  }

  const editor = createEditor()
  editor.children = fromNote.contentSnapshot

  const nodeEntries = getNodesByMatchingText(editor, text)

  const results: SuggestedBacklink[] = []

  return nodeEntries.reduce((result, nodeEntry) => {
    const [, entry] = nodeEntry

    const [parent] = Editor.parent(editor, entry)

    // Only match paragraphs (don't match headers)
    if (parent.type != ELEMENT_PARAGRAPH) return result

    const parentItem = getParentListItem(editor, entry) ?? parent
    const contextHtml = serializeHTMLFromNodes(parentItem.children, {
      stripLinks: true,
    })

    result.push({
      fromNoteId: fromNote.id,
      toNoteId: toNote.id,
      value: text,
      contextHtml,
    })

    return result
  }, results)
}
