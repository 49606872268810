import {SlateDocument} from '@udecode/slate-plugins'
import {getSnapshot} from 'mobx-keystone'
import {Graph, Note} from '../../app/models'
import {Link} from '../../app/models/link/link'
import {generateLinkContent} from '../../app/models/note/content-generators'
import {syncLinkHighlightsToContent} from '../../app/models/note/content-transforms'
import {generateId} from '../../plugins/generate-id'
import {db} from '../firebase'
import {DocumentSnapshot} from './document-snapshot'
import {GRAPHS_COLLECTION, LINKS_COLLECTION, Unsubscribe} from './types'

export const onLinksSnapshot = async (
  graph: Graph,
  callback: (links: Link[]) => void,
): Promise<Unsubscribe> => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  const linksDoc = graphDoc.collection(LINKS_COLLECTION)

  return linksDoc.onSnapshot((snapshot) => {
    callback(snapshot.docs.map(convertLink))
  })
}

export const syncLink = async (link: Link, graph: Graph) => {
  const noteStore = graph.noteStore

  // We need notes setup to continue
  if (!noteStore) return

  console.log(`[${graph.id}] syncing link`, link)

  link.setSynced(true)

  try {
    await obtainSyncLock(link, graph)
  } catch (error) {
    console.error(error)
    return
  }

  const note =
    noteStore.findByUrl(link.url) ||
    new Note({
      $modelId: generateId(),
      subject: link.title,
      content: generateLinkContent(link),
    })

  if (!note.noteStore) {
    noteStore.addNote(note)
  }

  const content = getSnapshot(note.content) as SlateDocument

  const syncedContent = syncLinkHighlightsToContent(content, link.highlights)

  note.updateContent(syncedContent)
}

const obtainSyncLock = (link: Link, graph: Graph) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  const linkDoc = graphDoc.collection(LINKS_COLLECTION).doc(link.id)

  return db.runTransaction(async (trans) => {
    const snapshot = await trans.get(linkDoc)

    if (!snapshot.exists) {
      throw new Error('unknown link')
    } else if (snapshot.data()!.synced) {
      return Promise.reject('already synced')
    } else {
      return trans.set(
        linkDoc,
        {
          synced: true,
        },
        {
          merge: true,
        },
      )
    }
  })
}

const convertLink = (doc: DocumentSnapshot): Link => {
  const data = doc.data()!

  return new Link({
    $modelId: doc.id,
    title: data.title,
    url: data.url,
    description: data.description,
    synced: data.synced ?? false,
    updatedAt: data.updated_at?.toDate(),
    highlights: data.highlights || [],
  })
}
