import * as React from 'react'
import {classNamesFunction, styled} from '@uifabric/utilities'
import {useFocused, useSelected} from 'slate-react'
import {
  BacklinkElementProps,
  BacklinkElementStyleProps,
  BacklinkElementStyles,
} from '../types'
import {getBacklinkElementStyles} from './BacklinkElement.styles'
import {useNoteStore} from '../../../../../app/models/store/context'
import {NotePreview} from '../../../..'
import {useHotkeys} from 'react-hotkeys-hook'
import {observer} from 'mobx-react-lite'

const getClassNames = classNamesFunction<
  BacklinkElementStyleProps,
  BacklinkElementStyles
>()

/**
 *   BacklinkElement with no default styles.
 * [Use the `styles` API to add your own styles.](https://github.com/OfficeDev/office-ui-fabric-react/wiki/Component-Styling)
 */
export const BacklinkElementBase = observer(
  ({
    attributes,
    children,
    element,
    className,
    styles,
    as: Tag = 'span',
  }: BacklinkElementProps) => {
    const selected = useSelected()
    const focused = useFocused()

    const classNames = getClassNames(styles, {
      className,
      // Other style props
      selected,
      focused,
    })

    const noteId = element.noteId as string

    // TODO: This is pretty gross - refactor
    const noteStore = useNoteStore()
    const note = noteStore?.findById(noteId)

    useHotkeys(
      'enter',
      (event) => {
        if (selected) {
          event.preventDefault()
          openNote()
        }
      },
      [noteId, selected],
    )

    const openNote = () => {
      note && noteStore?.setSelectedNote(note)
    }

    return (
      <Tag
        {...attributes}
        data-slate-value={element.value}
        contentEditable={false}
        className={classNames.root}
        onClick={() => openNote()}
      >
        <NotePreview note={note}>{element.value}</NotePreview>

        {children}
      </Tag>
    )
  },
)

/**
 * BacklinkElement
 */
export const BacklinkElement = styled<
  BacklinkElementProps,
  BacklinkElementStyleProps,
  BacklinkElementStyles
>(BacklinkElementBase, getBacklinkElementStyles, undefined, {
  scope: 'BacklinkElement',
})
