import {setDefaults} from '@udecode/slate-plugins'
import {Editor, Transforms} from 'slate'
import {DEFAULTS_TAG} from '../defaults'
import {TagNode, TagNodeData, TagPluginOptions} from '../types'

export const insertTag = (
  editor: Editor,
  tagable: TagNodeData,
  options?: TagPluginOptions,
) => {
  const {tag} = setDefaults(options, DEFAULTS_TAG)

  const tagNode: TagNode = {
    type: tag.type,
    children: [{text: ''}],
    ...tagable,
  }
  Transforms.insertNodes(editor, tagNode)
  Transforms.move(editor)
}
