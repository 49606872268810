import {Node} from 'slate'

export const serializeTextFromNodes = (content: Node[]) => {
  const serialize = (nodes: Node[], result: string[] = []) => {
    nodes.forEach((node) => {
      if (node.text) result.push(node.text as string)
      if (node.value) result.push(node.value as string)
      if (node.children) serialize(node.children as Node[], result)
    })

    return result.join('\n')
  }

  return serialize(content)
}
