import {storage} from '../firebase'
import {generateId} from '../../plugins/generate-id'
import {getAuthUser} from './helpers'

export const uploadFile = async (file: File) => {
  const authUser = await getAuthUser()
  const docId = generateId()
  const stringRef = `users/${authUser.uid}/${docId}`
  const storageRef = storage.ref(stringRef)

  await storageRef.put(file, {
    contentType: file.type,
  })

  return await storageRef.getDownloadURL()
}
