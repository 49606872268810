import {Model, model, modelAction, prop} from 'mobx-keystone'
import {onCredentialsSnapshot} from '../../../services/api'
import {Credential} from './credential'
@model('CredentialStore')
export class CredentialStore extends Model({
  credentials: prop<Credential[]>(() => []),
}) {
  @modelAction
  setCredentials(credentials: Credential[]) {
    this.credentials = credentials
  }

  onAttachedToRootStore() {
    const snapshot = onCredentialsSnapshot((snapshots) => {
      this.setCredentials(snapshots)
    })

    return async () => (await snapshot)()
  }
}
