import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_LI,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
} from '@udecode/slate-plugins'
import {ELEMENT_BACKLINK} from '../../plugins/backlink'

export interface NodeTypes {
  paragraph?: string
  block_quote?: string
  code_block?: string
  link?: string
  ul_list?: string
  ol_list?: string
  list_item?: string
  heading?: {
    1?: string
    2?: string
    3?: string
    4?: string
    5?: string
    6?: string
  }
  todo_list_item?: string
  backlink?: string
}

export const defaultNodeTypes: NodeTypes = {
  paragraph: ELEMENT_PARAGRAPH,
  block_quote: ELEMENT_BLOCKQUOTE,
  code_block: ELEMENT_CODE_BLOCK,
  link: ELEMENT_LINK,
  ul_list: ELEMENT_UL,
  ol_list: ELEMENT_OL,
  list_item: ELEMENT_LI,
  todo_list_item: ELEMENT_TODO_LI,
  heading: {
    1: ELEMENT_H1,
    2: ELEMENT_H2,
    3: ELEMENT_H3,
    4: ELEMENT_H4,
    5: ELEMENT_H5,
    6: ELEMENT_H6,
  },
  backlink: ELEMENT_BACKLINK,
}
