import {format, subDays} from 'date-fns'
import {template} from 'lodash'
import reflectNoteContent from './reflect-note.json'
import dailyNoteContent from './daily-note.json'
import {Note} from '../note'
import {generateContactContent, generateNoteWithList} from '../content-generators'
import {Contact} from '../../contact/contact'
import {User} from '../../user/user'

export const REFLECT_NOTE_ID = 'reflect'

const renderTemplate = (json: any, vars = {}) => {
  const compiled = template(JSON.stringify(json))
  return JSON.parse(compiled(vars))
}

export const getInitialNotes = (user: User) => {
  const today = new Date()

  const dailyNoteId = format(today, 'ddMMyyyy')
  const dailyNoteSubject = format(today, 'MMMM do, yyyy')

  const yesterday = subDays(today, 1)
  const yesterdayNoteId = format(yesterday, 'ddMMyyyy')
  const yesterdayNoteSubject = format(yesterday, 'MMMM do, yyyy')

  const renderedDailyNoteContent = renderTemplate(dailyNoteContent, {
    DAILY_NOTE_SUBJECT: dailyNoteSubject,
    REFLECT_NOTE_ID,
  })

  return {
    dailyNote: new Note({
      $modelId: dailyNoteId,
      subject: dailyNoteSubject,
      content: renderedDailyNoteContent,
      daily: true,
      pinned: false,
      createdAt: today,
      updatedAt: today,
    }),

    yeterdayNote: new Note({
      $modelId: yesterdayNoteId,
      subject: yesterdayNoteSubject,
      content: generateNoteWithList(yesterdayNoteSubject),
      daily: true,
      pinned: false,
      createdAt: yesterday,
      updatedAt: yesterday,
    }),

    reflectNote: new Note({
      $modelId: REFLECT_NOTE_ID,
      subject: 'How to use Reflect',
      content: reflectNoteContent,
      daily: false,
      pinned: true,
      createdAt: today,
      updatedAt: today,
    }),

    personNote: new Note({
      $modelId: 'alexmaccaw',
      subject: 'Alex MacCaw',
      content: generatePersonContent(),
      daily: false,
      pinned: false,
      createdAt: today,
      updatedAt: today,
    }),

    meNote: new Note({
      $modelId: 'me',
      subject: user.name,
      content: generateMeContent(user),
      daily: false,
      pinned: false,
      createdAt: today,
      updatedAt: today,
    }),
  }
}

const generatePersonContent = () => {
  const contact = new Contact({
    givenName: 'Alex',
    familyName: 'MacCaw',
    emails: [{primary: true, value: 'alex@alexmaccaw.com'}, {value: 'alex@reflect.app'}],
  })

  return generateContactContent(contact)
}

const generateMeContent = (user: User) => {
  const contact = new Contact({
    givenName: user.givenName,
    familyName: user.familyName,
    emails: [{primary: true, value: user.email}],
  })

  return generateContactContent(contact)
}
