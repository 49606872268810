import {
  DEFAULTS_ALIGN,
  DEFAULTS_BLOCKQUOTE,
  DEFAULTS_BOLD,
  DEFAULTS_CODE,
  DEFAULTS_CODE_BLOCK,
  DEFAULTS_KBD,
  DEFAULTS_HIGHLIGHT,
  DEFAULTS_IMAGE,
  DEFAULTS_ITALIC,
  DEFAULTS_LINK,
  DEFAULTS_MEDIA_EMBED,
  DEFAULTS_MENTION,
  DEFAULTS_PARAGRAPH,
  DEFAULTS_SEARCH_HIGHLIGHT,
  DEFAULTS_STRIKETHROUGH,
  DEFAULTS_SUBSUPSCRIPT,
  DEFAULTS_TABLE,
  DEFAULTS_TODO_LIST,
  DEFAULTS_UNDERLINE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_LI,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  ResetBlockTypePluginOptions,
  HeadingKeyOption,
  HeadingPluginOptionsValues,
  HeadingLevelsOption,
  StyledElement,
  ListKeyOption,
  ListPluginOptionsValues,
  SlateDocument,
  setDefaults,
} from '@udecode/slate-plugins'
import urlRegexSafe from 'url-regex-safe'
import phoneRegex from 'phone-regex-ts'
import {uploadFile} from '../../services/api'
import {DEFAULTS_BACKLINK} from './plugins/backlink'
import {BreakListPluginOptions} from './plugins/break-list/types'
import {ExpandableListItemElement} from './plugins/expandable-list/expandable-list-item-element'
import {ExpandableUnorderedListElement} from './plugins/expandable-list/expandable-unordered-list-element'
import {LinkElement} from './plugins/link/link-element'
import {TodoListElement} from './plugins/todo-list/todo-list-element'
import {DEFAULTS_TAG} from './plugins/tags'

export const headingTypes = [
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
]

export const DEFAULTS_HEADING: Record<HeadingKeyOption, HeadingPluginOptionsValues> &
  Required<HeadingLevelsOption> = {
  h1: {
    component: StyledElement,
    type: ELEMENT_H1,
    rootProps: {
      className: `slate-${ELEMENT_H1}`,
      as: 'h1',
      styles: {},
    },
  },
  h2: {
    component: StyledElement,
    type: ELEMENT_H2,
    rootProps: {
      className: `slate-${ELEMENT_H2}`,
      as: 'h2',
      styles: {},
    },
  },
  h3: {
    component: StyledElement,
    type: ELEMENT_H3,
    rootProps: {
      className: `slate-${ELEMENT_H3}`,
      as: 'h3',
      styles: {},
    },
  },
  h4: {
    component: StyledElement,
    type: ELEMENT_H4,
    rootProps: {
      className: `slate-${ELEMENT_H4}`,
      as: 'h4',
      styles: {},
    },
  },
  h5: {
    component: StyledElement,
    type: ELEMENT_H5,
    rootProps: {
      className: `slate-${ELEMENT_H5}`,
      as: 'h5',
      styles: {},
    },
  },
  h6: {
    component: StyledElement,
    type: ELEMENT_H6,
    rootProps: {
      className: `slate-${ELEMENT_H6}`,
      as: 'h6',
      styles: {},
    },
  },
  levels: 6,
}

export const DEFAULTS_LIST: Record<ListKeyOption, ListPluginOptionsValues> = {
  ul: {
    component: ExpandableUnorderedListElement,
    type: ELEMENT_UL,
    rootProps: {
      className: 'slate-ul',
      as: 'ul',
      styles: {
        root: {
          paddingInlineStart: '0',
          marginBlockStart: '0',
          marginBlockEnd: '0',
        },
      },
    },
  },
  ol: {
    component: StyledElement,
    type: ELEMENT_OL,
    rootProps: {
      className: 'slate-ol',
      as: 'ol',
      styles: {
        root: {
          paddingInlineStart: '0',
          marginBlockStart: '0',
          marginBlockEnd: '0',
        },
      },
    },
  },
  li: {
    component: ExpandableListItemElement,
    type: ELEMENT_LI,
    rootProps: {
      className: 'slate-li',
      as: 'li',
      styles: {
        root: {
          paddingInlineStart: '0',
          marginBlockStart: '0',
          marginBlockEnd: '0',
        },
      },
    },
  },
  ...DEFAULTS_PARAGRAPH,
}

const isUrlRegexSafe = urlRegexSafe({
  exact: true,
})

const isPhoneRegex = phoneRegex({
  exact: true,
})

export const options = {
  ...setDefaults(DEFAULTS_PARAGRAPH, {}),
  ...setDefaults(DEFAULTS_MENTION, {}),
  ...setDefaults(DEFAULTS_BACKLINK, {}),
  ...setDefaults(DEFAULTS_TAG, {}),
  ...setDefaults(DEFAULTS_BLOCKQUOTE, {}),
  ...setDefaults(DEFAULTS_CODE_BLOCK, {}),
  ...setDefaults(DEFAULTS_IMAGE, {}),
  ...setDefaults(DEFAULTS_MEDIA_EMBED, {}),
  todo_li: {
    ...setDefaults(DEFAULTS_TODO_LIST.todo_li, {}),
    component: TodoListElement,
  },
  ...setDefaults(DEFAULTS_TABLE, {}),
  ...setDefaults(DEFAULTS_LIST, {}),
  ...setDefaults(DEFAULTS_HEADING, {}),
  ...setDefaults(DEFAULTS_ALIGN, {}),
  // marks
  ...setDefaults(DEFAULTS_BOLD, {}),
  ...setDefaults(DEFAULTS_ITALIC, {}),
  ...setDefaults(DEFAULTS_UNDERLINE, {}),
  ...setDefaults(DEFAULTS_STRIKETHROUGH, {}),
  ...setDefaults(DEFAULTS_CODE, {}),
  ...setDefaults(DEFAULTS_KBD, {}),
  ...setDefaults(DEFAULTS_SUBSUPSCRIPT, {}),
  ...setDefaults(DEFAULTS_HIGHLIGHT, {}),
  ...setDefaults(DEFAULTS_SEARCH_HIGHLIGHT, {}),

  link: {
    ...setDefaults(DEFAULTS_LINK.link, {}),
    component: LinkElement,
    rootProps: {
      className: 'slate-link',
    },
  },

  isUrl: (str: string) => {
    return isUrlRegexSafe.test(str) || isPhoneRegex.test(str)
  },

  linkHost: 'reflect.app',
}

export const inlineTypes = [options.mention.type, options.link.type]

const resetBlockTypesCommonRule = {
  types: [options.blockquote.type, options.code_block.type, options.todo_li.type],
  defaultType: options.p.type,
}

export const optionsResetBlockTypes: ResetBlockTypePluginOptions = {
  rules: [
    {
      ...resetBlockTypesCommonRule,
      hotkey: 'Enter',
      predicate: isBlockAboveEmpty,
    },
    {
      ...resetBlockTypesCommonRule,
      hotkey: 'Backspace',
      predicate: isSelectionAtBlockStart,
    },
  ],
}

export const optionsSoftBreak = {
  rules: [
    {hotkey: 'shift+enter'},
    {
      hotkey: 'enter',
      query: {
        allow: [options.code_block.type, options.blockquote.type, options.td.type],
      },
    },
  ],
}

export const optionsExitBreak = {
  rules: [
    {
      hotkey: 'mod+enter',
    },
    {
      hotkey: 'mod+shift+enter',
      before: true,
    },
    {
      hotkey: 'enter',
      query: {
        start: true,
        end: true,
        allow: headingTypes,
      },
    },
  ],
}

export const optionsBreakList: BreakListPluginOptions = {
  rules: [
    {
      types: [options.h1.type],
      hotkey: 'Enter',
    },
  ],
}

export const optionsFileUploadCallback = async (file: File) => {
  return await uploadFile(file)
}

export const defaultValue = (subject = ''): SlateDocument => [
  {
    children: [
      {
        type: options.h1.type,
        children: [{text: subject || ''}],
      },
    ],
  },
]
