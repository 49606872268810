import {ListPluginOptions} from '@udecode/slate-plugins'
import {SlatePlugin} from '@udecode/slate-plugins-core'
import {onKeyDownExpandableList} from './on-key-down-expandable-list'

/**
 * Enables support for bulleted, numbered and to-do lists.
 */
export const ExpandableListPlugin = (options?: ListPluginOptions): SlatePlugin => ({
  onKeyDown: onKeyDownExpandableList(options),
})
