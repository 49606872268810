import {onUserSnapshot, APIUser} from '../../../services/api'
import {detach, Model, model, modelAction, prop} from 'mobx-keystone'
import {computed} from 'mobx'
import {Graph} from './graph'

@model('GraphStore')
export class GraphStore extends Model({
  currentGraphId: prop<string | null>(null),
  graphs: prop<Graph[]>(() => []),
  volatileGraphs: prop<Graph[]>(() => []),
}) {
  @computed
  get allGraphs() {
    return this.graphs.concat(this.volatileGraphs)
  }

  @computed
  get currentGraph() {
    return this.allGraphs.find((graph) => graph.id === this.currentGraphId)
  }

  @computed
  get ownedGraphs() {
    return this.allGraphs.filter((graph) => graph.isOwner)
  }

  findById(id: string) {
    return this.allGraphs.find((graph) => graph.id === id)
  }

  setCurrentGraph(graph: Graph) {
    this.setCurrentGraphId(graph.id)
  }

  @modelAction
  setCurrentGraphId(graphId: string | null) {
    this.currentGraphId = graphId
  }

  @modelAction
  ensureGraphId(graphId: string, volatile = false) {
    if (!this.findById(graphId)) {
      console.info('Loading graph:', graphId)

      if (volatile) {
        this.volatileGraphs.push(new Graph({$modelId: graphId}))
      } else {
        this.graphs.push(new Graph({$modelId: graphId}))
      }
    }
  }

  @modelAction
  onUserSnapshot(user: APIUser) {
    // Add graphs that are missing
    user.graphIds.forEach((id) => this.ensureGraphId(id))

    // Remove graphs that are not
    for (const graph of this.graphs) {
      if (!user.graphIds.includes(graph.id)) detach(graph)
    }

    // Did we load an invalid currentGraphId from storage?
    if (this.currentGraphId && !this.findById(this.currentGraphId)) {
      this.currentGraphId = null
    }

    if (!this.currentGraphId) {
      this.setCurrentGraphId(user.graphIds[0])
    }
  }

  @modelAction
  addGraph(graph: Graph) {
    const existingGraph = this.findById(graph.id)

    if (existingGraph) {
      detach(existingGraph)
    }

    this.graphs.push(graph)
  }

  private listeners: any[] = []

  onAttachedToRootStore() {
    console.log('Graph store setup')

    this.listeners.push(onUserSnapshot((snapshot) => this.onUserSnapshot(snapshot)))

    return async () => await this.beforeDetach()
  }

  async beforeDetach() {
    for (const unsubscribe of this.listeners) {
      ;(await Promise.resolve(unsubscribe))()
    }
  }
}
