import {parse} from 'date-fns'
import {Graph, Note} from '../../app/models'
import {generateContentFromRoamNote} from '../../app/models/note/content-generators'
import {generateId} from '../../plugins/generate-id'
import {toId} from '../../plugins/to-id'
import {RoamNote} from './import.types'
import {batchCreateNotes} from './notes'

export const importRoamNotes = async (graph: Graph, file: File) => {
  const data = await readFile(file)
  const roamNotes = JSON.parse(data) as RoamNote[]
  await importNotes(graph, roamNotes)
}

// Helpers

const readFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = () => reject(reader.error)
  })
}

const importNotes = async (graph: Graph, roamNotes: RoamNote[]) => {
  const notes = roamNotes.map((roamNote) => buildNote(graph, roamNote))
  await batchCreateNotes(notes, graph)
}

const buildNote = (graph: Graph, roamNote: RoamNote) => {
  const content = generateContentFromRoamNote(roamNote)
  const noteId = roamNote.title ? toId(roamNote.title) : generateId()
  const daily = isDailyNote(roamNote)

  const updatedAt = new Date(roamNote['edit-time'])
  const createdAt = parseDate(roamNote.title) || updatedAt

  return new Note({
    $modelId: noteId,
    subject: roamNote.title || '',
    content,
    daily,
    pinned: false,
    createdAt,
    updatedAt,
  })
}

const MONTH_RE = /^(January|February|March|April|May|June|July|August|September|October|November|December)/

const parseDate = (str: string): Date | null => {
  if (!MONTH_RE.test(str)) return null

  try {
    // August 5th, 2020
    return parse(str, 'MMMM do, yyyy', new Date())
  } catch (_) {
    return null
  }
}

const isDailyNote = (roamNote: RoamNote) => MONTH_RE.test(roamNote.title)
