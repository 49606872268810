import {
  detach,
  getParent,
  getRootStore,
  Model,
  model,
  modelAction,
  prop,
} from 'mobx-keystone'
import {Note} from './note'
import {Backlink, BacklinkCallback, BacklinkNodeData} from '../backlink/backlink'
import {SuggestedBacklink, suggestedIncomingBacklinks} from './suggested-backlink'
import {addSuggestedBacklinkToNodes, mergeContent} from './content-transforms'
import {generateContactContent, PERSON_TAG} from './content-generators'
import {Contact} from '../contact/contact'
import {
  buildModel,
  createDailyNote,
  setNote,
  deleteNote,
  reEncryptNotes,
} from '../../../services/api'
import {setupSnapshotsListener} from './snapshot'
import {action, autorun, computed, observable} from 'mobx'
import {Graph} from '../graph/graph'
import {has} from 'lodash'
import {RootStore} from '../store/root-store'
import {Meeting} from '../meeting/meeting'
import {analytics} from '../../../services/analytics'

type NoteView = 'list' | 'edit' | 'brain'

export type SelectingEdge = 'default' | 'top' | 'bottom'

@model('NoteStore')
export class NoteStore extends Model({
  notes: prop<Note[]>(() => []),
}) {
  @observable
  view: NoteView = 'edit'

  @observable
  selectedNoteId: string | null = null

  @observable
  noteSelectingEdge: SelectingEdge = 'default'

  @observable
  searchQuery: string | null = null

  @observable
  quickSearchQuery: string | null = null

  get rootStore(): RootStore | undefined {
    return getRootStore<RootStore>(this)
  }

  get assertRootStore(): RootStore {
    const rootStore = this.rootStore
    if (!rootStore) throw new Error('rootStore blank')
    return rootStore
  }

  get graph(): Graph | undefined {
    return getParent<Graph>(this)
  }

  get assertGraph(): Graph {
    const graph = this.graph

    if (!graph) {
      throw new Error('graph blank')
    }

    return graph
  }

  get graphId(): string | undefined {
    return this.graph?.id
  }

  get assertGraphId(): string {
    return this.assertGraph.id
  }

  @computed
  get selectedNote(): Note | undefined {
    if (this.selectedNoteId) {
      return this.findById(this.selectedNoteId)
    } else {
      const firstNote = this.firstNote

      if (firstNote) {
        this.setSelectedNote(firstNote)
      }

      return firstNote
    }
  }

  isSelected(note: Note): boolean {
    return this.selectedNote === note
  }

  @computed
  get deletedNotes(): Note[] {
    return this.notes.filter((note) => note.deletedAt)
  }

  @computed
  get undeletedNotes(): Note[] {
    return this.notes.filter((note) => !note.deletedAt)
  }

  @computed
  get orderedDeletedNotes(): Note[] {
    return this.deletedNotes
      .slice()
      .sort((a, b) => b.updatedAtTimestamp - a.updatedAtTimestamp)
  }

  @computed
  get orderedNotesByUpdated(): Note[] {
    return this.undeletedNotes
      .slice()
      .sort((a, b) => b.updatedAtTimestamp - a.updatedAtTimestamp)
  }

  @computed
  get orderedNotesByCreated(): Note[] {
    return this.undeletedNotes
      .slice()
      .sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp)
  }

  @computed
  get orderedNotesByBacklinkedCount(): Note[] {
    return this.undeletedNotes
      .slice()
      .sort((a, b) => b.updatedAtTimestamp - a.updatedAtTimestamp)
      .sort((a, b) => b.backlinkedCount - a.backlinkedCount)
  }

  get orderedNotes(): Note[] {
    return this.orderedNotesByUpdated
  }

  @computed
  get orderedNoneDailyNotes(): Note[] {
    return this.orderedNotesByCreated.filter((n) => !n.daily)
  }

  @computed
  get orderedDailyNotes(): Note[] {
    return this.orderedNotesByCreated.filter((n) => n.daily)
  }

  @computed
  get pinnnedNotes(): Note[] {
    return this.orderedNotesByUpdated.filter((n) => n.pinned)
  }

  filteredNotes(query: string): Note[] {
    return this.undeletedNotes
      .map((note) => [note, note.matchIndex(query)] as any)
      .filter(([, index]) => index != -1)
      .sort(
        ([aNote, aIndex], [bNote, bIndex]) =>
          // Sort by match index, and then timestamp
          bIndex - aIndex || bNote.updatedAtTimestamp - aNote.updatedAtTimestamp,
      )
      .map(([note]) => note)
  }

  @computed
  get todaysDailyNote(): Note | undefined {
    return this.undeletedNotes.find((n) => n.isTodaysDailyNote)
  }

  @computed
  get firstNote(): Note {
    return this.orderedDailyNotes[0] || this.orderedNoneDailyNotes[0]
  }

  @computed
  get backlinks(): Backlink[] {
    const backlinks = this.undeletedNotes.map((n) => n.outgoingBacklinks).flat()
    return backlinks.filter((bl) => {
      if (!this.isValidNoteId(bl.fromNoteId)) return false
      if (!this.isValidNoteId(bl.toNoteId)) return false
      return true
    })
  }

  @computed
  get availableBacklinks(): BacklinkNodeData[] {
    const notes = this.orderedNotesByBacklinkedCount
    const backlinkableNotes = notes.filter((n) => n.subject)
    return backlinkableNotes.map((note) => ({
      note,
      value: note.subject,
      photoUrl: note.photoUrl,
    }))
  }

  @computed
  get availableMergeNotes(): BacklinkNodeData[] {
    const backlinkableNotes = this.orderedNotesByUpdated.filter((n) => !!n.subject)
    return backlinkableNotes.map((note) => ({note, value: note.subject}))
  }

  incomingBacklinks(note: Note): Backlink[] {
    return this.orderedNotesByUpdated
      .filter((n) => n.id !== note.id)
      .map((n) => n.outgoingBacklinks.filter((bl) => bl.toNoteId === note.id))
      .flat()
  }

  suggestedIncomingBacklinks(note: Note): SuggestedBacklink[] {
    const backlinks = this.undeletedNotes
      .map((n: Note) => suggestedIncomingBacklinks(n, note))
      .flat()

    return backlinks.filter(
      (backlink) =>
        backlink.fromNoteId != note.id &&
        !note.ignoredBacklinkFromNoteIds.includes(backlink.fromNoteId),
    )
  }

  findById(noteId: string): Note | undefined {
    return this.notes.find((n) => n.id === noteId)
  }

  filterByTag(tag: string): Note[] {
    return this.undeletedNotes.filter((note) => note.tags.includes(tag))
  }

  findBySubject(subject: string) {
    return this.undeletedNotes.find((n) => n.subject === subject)
  }

  findByAsin(asin: string) {
    return this.undeletedNotes.find((n) => n.contentMetadataAsin === asin)
  }

  findByUrl(url: string) {
    return this.undeletedNotes.find((n) => n.contentMetadataUrl === url)
  }

  findPersonTagByEmail(email: string) {
    return this.filterByTag(PERSON_TAG).find((note) => note.contentEmails.includes(email))
  }

  findPersonTagBySubject(subject: string) {
    return this.filterByTag(PERSON_TAG).find((note) => note.subject === subject)
  }

  findByContact(contact: Contact): Note | undefined {
    for (const email of contact.emailValues) {
      const note = this.findPersonTagByEmail(email)
      if (note) return note
    }

    if (contact.name) {
      const note = this.findPersonTagBySubject(contact.name)
      if (note) return note
    }
  }

  isValidNoteId(noteId: string) {
    return this.notes.findIndex((n) => n.id === noteId) != -1
  }

  availableMergeNotesForNote(note: Note): BacklinkNodeData[] {
    return this.availableMergeNotes.filter((bl) => bl.note != note)
  }

  // Actions

  findOrCreateByContact(contact: Contact) {
    let note = this.findByContact(contact)

    note =
      note ||
      this.createNote({
        subject: contact.name,
        content: generateContactContent(contact),
      })

    return note
  }

  @action
  setQuickSearchQuery(query: string | null) {
    this.quickSearchQuery = query
  }

  @action
  openQuickSearch() {
    this.quickSearchQuery = ''
  }

  @action
  setSearchQuery(query: string | null) {
    this.searchQuery = query
  }

  @action
  setView(view: NoteView) {
    this.view = view
  }

  @modelAction
  setNotes(notes: Note[]) {
    this.notes = notes
    this.ensureSelectedNote()
  }

  @modelAction
  ensureSelectedNote() {
    if (!this.selectedNote) {
      this.selectFirstNote()
    }
  }

  setAndOpenSearchQuery(query: string) {
    this.setSearchQuery(query)
    this.setView('list')
  }

  setAndOpenSelectedNote(note: Note) {
    this.setSelectedNote(note)
    this.setView('edit')
  }

  setSelectedNote(note: Note | null) {
    this.setSelectedNoteId(note?.id || null)
  }

  @modelAction
  viewList() {
    this.setView('list')
    this.setSelectedNote(null)
  }

  @action
  setSelectedNoteId(noteId: string | null) {
    this.selectedNoteId = noteId
  }

  editNote(note: Note) {
    this.setSelectedNote(note)
    this.setView('edit')
  }

  selectFirstNote() {
    this.setSelectedNote(null)
  }

  selectDailyNote() {
    this.setView('edit')
    this.setSelectedNote(this.todaysDailyNote || null)
  }

  @modelAction
  deleteNote(note: Note) {
    note.setDeletedAt()
    if (this.isSelected(note)) this.viewList()
  }

  @modelAction
  destroyNote(note: Note) {
    const graphId = this.assertGraphId
    const noteId = note.id
    const isSelected = this.isSelected(note)

    detach(note)
    deleteNote(graphId, noteId)

    if (isSelected) this.viewList()
  }

  @modelAction
  createNote(props: any = {}): Note {
    const note: Note = buildModel<Note>(Note, props)

    if (has(props, 'content')) {
      // Content has some setters we need to trigger
      note.setContent(props['content'])
    }

    this.addNote(note)
    setNote(note)

    analytics?.track('note_created', {
      daily: note.daily,
    })

    return note
  }

  createAndOpenNote(props = {}) {
    const note = this.createNote(props)
    this.editNote(note)
  }

  findOrCreateBySubject(subject: string) {
    return this.findBySubject(subject) || this.createNote({subject})
  }

  onAddBacklink(backlink: BacklinkNodeData): BacklinkCallback {
    let note = backlink.note

    if (!note) {
      const content = backlink.contact ? generateContactContent(backlink.contact) : []

      note = this.createNote({
        subject: backlink.value,
        content,
      })
    }

    note.incrementBacklinkedCount()

    return {
      value: backlink.value,
      noteId: note.id,
      graphId: this.assertGraphId,
    }
  }

  addSuggestedBacklink(backlink: SuggestedBacklink) {
    const fromNote = this.findById(backlink.fromNoteId)

    if (!fromNote) {
      throw new Error('Unknown fromNoteId:' + backlink.fromNoteId)
    }

    let toNote: Note | undefined

    if (backlink.toNoteId) {
      toNote = this.findById(backlink.toNoteId)
    }

    if (backlink.value) {
      toNote = this.findBySubject(backlink.value)
    }

    toNote = toNote ?? this.createNote({subject: backlink.value})

    const newContent = addSuggestedBacklinkToNodes(fromNote.contentSnapshot, {
      ...backlink,
      toNoteId: toNote.id,
    })

    fromNote.updateContent(newContent)
    fromNote.ignoreSuggestedBacklink(backlink)

    toNote.incrementBacklinkedCount()
  }

  @modelAction
  mergeNotes(mergeFromNote: Note, mergeToNote: Note) {
    this.notes.forEach((note) => note.rewriteBacklinks(mergeFromNote.id, mergeToNote.id))

    mergeToNote.updateContent(
      mergeContent(mergeFromNote.contentSnapshot, mergeToNote.contentSnapshot),
      {
        rerender: true,
        debounce: false,
      },
    )

    this.setSelectedNote(mergeToNote)
    this.deleteNote(mergeFromNote)
  }

  addMeeting({note, meeting}: {note: Note; meeting: Meeting}) {
    const currentUser = this.assertRootStore.assertCurrentUser

    const attendeeNotes: Note[] = []

    for (const contact of meeting.contacts) {
      // Do not backlink yourself
      if (!contact.name) continue
      if (contact.name === currentUser.name) continue

      const contactNote = this.findOrCreateByContact(contact)

      attendeeNotes.push(contactNote)
    }

    let meetingNote: Note | undefined

    if (meeting.recurring && meeting.name) {
      meetingNote = this.findOrCreateBySubject(meeting.name)
    }

    note.addMeeting({meetingNote, attendeeNotes})
  }

  @modelAction
  reset() {
    this.notes = []
  }

  @modelAction
  addNote(note: Note) {
    if (!note) throw new Error('Cannot add null note')
    if (!note.id) {
      console.error('Malformed note', note)
      throw new Error('Cannot add a note with blank id')
    }

    this.notes.push(note)
  }

  @modelAction
  replaceNotes(notes: Note[]) {
    this.notes = notes
  }

  async reEncryptNotes() {
    await reEncryptNotes(this.notes, this.assertGraph)
  }

  private listeners: any = []

  onAttachedToRootStore() {
    if (!this.graph) throw new Error('unknown graph')

    this.listeners.push(setupSnapshotsListener(this))

    this.listeners.push(
      autorun(() => {
        if (!this.todaysDailyNote && this.graph?.isOwner) {
          try {
            createDailyNote(this.graph)
          } catch (err) {
            console.error('Daily note', err)
          }
        }
      }),
    )

    return async () => await this.beforeDetach()
  }

  async beforeDetach() {
    console.log(`[${this.graph?.id}]`, 'detaching notes')
    for (const unsubscribe of this.listeners) {
      ;(await Promise.resolve(unsubscribe))()
    }
  }
}
