import {
  getParent,
  getRootStore,
  getSnapshot,
  Model,
  model,
  modelAction,
  prop,
  prop_dateString,
} from 'mobx-keystone'
import {now} from 'mobx-utils'
import {Node} from 'slate'
import {debounce, truncate} from 'lodash'
import {format, isToday, isThisWeek, isSameDay} from 'date-fns'
import {Backlink} from '../backlink/backlink'
import {
  contentDomains,
  contentEmails,
  contentLine,
  contentMetadata,
  contentPhoneNumbers,
  contentSubject,
  contentTags,
  getBacklinks,
} from './content-queries'
import {SuggestedBacklink} from './suggested-backlink'
import {serializeMarkdownFromNodes} from '../../../components/rich-text/serializers'
import {serializeHTMLFromNodes} from '../../../components/rich-text/serializers'
import {Contact} from '../contact/contact'
import {
  addContactToContent,
  addMeetingToContent,
  rewriteBacklinks,
} from './content-transforms'
import {toSlug} from '../../../plugins/to-slug'
import {SlateDocument} from '@udecode/slate-plugins'
import {setNote} from '../../../services/api'
import {options} from '../../../components/rich-text/options'
import {action, computed, observable} from 'mobx'
import {RootStore} from '../store/root-store'
import {NoteStore} from './note-store'
import {Graph} from '../graph/graph'
import {SystemMetadata} from './system-metadata'
import {ContentMetadata} from './content-metadata'
import {enrich} from '../../../services/api'

@model('Note')
export class Note extends Model({
  subject: prop<string>(() => ''),
  content: prop<Node[]>(() => []),
  contentMetadata: prop<ContentMetadata>(() => ({})),
  systemMetadata: prop<SystemMetadata>(() => ({})),
  ignoredBacklinkValues: prop<string[]>(() => []),
  ignoredBacklinkFromNoteIds: prop<string[]>(() => []),
  ignoredContactNames: prop<string[]>(() => []),
  backlinkedCount: prop<number>(() => 0),
  acl: prop<string[]>(() => []),
  pinned: prop(false),
  daily: prop(false),
  createdAt: prop_dateString<Date>(() => new Date()),
  updatedAt: prop_dateString<Date>(() => new Date()),
  deletedAt: prop_dateString<Date | null>(() => null),
}) {
  @observable
  version = 1

  @computed
  get key(): string {
    return [this.id, this.version].join('-')
  }

  get id(): string {
    return this.$modelId
  }

  aclCanRead(uid: string): boolean {
    return (
      this.aclCanWrite(uid) ||
      this.acl.includes('public') ||
      this.acl.includes(`read.${uid}`)
    )
  }

  aclCanWrite(uid: string): boolean {
    return this.aclIsOwner(uid) || this.acl.includes(`write.${uid}`)
  }

  aclIsOwner(uid: string): boolean {
    return this.acl.includes(`owner.${uid}`)
  }

  get noteStore(): NoteStore | undefined {
    const notes = getParent<Note[]>(this)
    return notes && getParent<NoteStore>(notes)
  }

  get graph(): Graph | undefined {
    return this.noteStore?.graph
  }

  get assertGraph(): Graph {
    const graph = this.graph
    if (!graph) throw new Error('blank graph')
    return graph
  }

  get graphId(): string | undefined {
    return this.graph?.id
  }

  @computed
  get contentSnapshot(): Node[] {
    return getSnapshot(this.content)
  }

  @computed
  get title(): string {
    return this.subject || 'Untitled'
  }

  @computed
  get slug(): string {
    if (!this.subject) return this.id
    const subjectSlug = toSlug(this.subject)
    return `${subjectSlug}-${this.id}`
  }

  @computed
  get contentJson(): string {
    return JSON.stringify(this.content)
  }

  @computed
  get contentLine(): string {
    return contentLine(this.content)
  }

  @computed
  get contentHtml(): string {
    return serializeHTMLFromNodes(this.content, options)
  }

  @computed
  get contentMarkdown(): string {
    return serializeMarkdownFromNodes(this.content)
  }

  @computed
  get contentEmails(): string[] {
    return contentEmails(this.content as SlateDocument)
  }

  @computed
  get contentPhoneNumbers(): string[] {
    return contentPhoneNumbers(this.content as SlateDocument)
  }

  @computed
  get contentDomains(): string[] {
    return contentDomains(this.content as SlateDocument)
  }

  @computed
  get contentTags(): string[] {
    return contentTags(this.content as SlateDocument)
  }

  @computed
  get contentMetadataAsin(): string {
    return this.contentMetadata['asin']
  }

  @computed
  get contentMetadataUrl(): string {
    return this.contentMetadata['url']
  }

  @computed
  get contentMentions(): {
    emails: string[]
    domains: string[]
    tags: string[]
  } {
    return {
      emails: this.contentEmails,
      domains: this.contentDomains,
      tags: this.contentTags,
    }
  }

  @computed
  get tags(): string[] {
    return this.contentTags
  }

  @computed
  get snippet(): string {
    return truncate(this.contentLine, {length: 70})
  }

  @computed
  get createdAtTimestamp(): number {
    return (this.createdAt || this.createdAt)?.getTime()
  }

  @computed
  get updatedAtTimestamp(): number {
    return (this.updatedAt || this.updatedAt)?.getTime()
  }

  @computed
  get updatedAtFormatted(): string {
    if (isToday(this.updatedAt)) {
      return format(this.updatedAt, 'hh:mm a')
    } else if (isThisWeek(this.updatedAt)) {
      return format(this.updatedAt, 'eee')
    } else {
      return format(this.updatedAt, 'MM/dd/yyyy')
    }
  }

  @computed
  get updatedAtFormattedDatestamp(): string {
    return format(this.updatedAt, 'MM/dd/yyyy')
  }

  @computed
  get createdAtToday(): boolean {
    return isToday(this.createdAt)
  }

  isCreatedOnDay(day: number | Date): boolean {
    return isSameDay(this.createdAt, day)
  }

  @computed
  get isTodaysDailyNote(): boolean {
    return this.daily && isSameDay(this.createdAt, now(1000 * 5))
  }

  @computed
  get outgoingBacklinks(): Backlink[] {
    return getBacklinks(this.contentSnapshot, this.id)
  }

  @computed
  get incomingBacklinks(): Backlink[] {
    return this.noteStore?.incomingBacklinks(this) ?? []
  }

  @computed
  get enrichment(): any | null {
    return this.systemMetadata?.enrichment ?? null
  }

  @computed
  get photoUrl(): string {
    return this.enrichment?.person?.avatar || this.enrichment?.company?.logo
  }

  @computed
  get enrichmentMatchText(): string {
    const enrichment = this.enrichment

    if (!enrichment?.person || !enrichment?.company) return ''

    return [
      enrichment.person?.name?.fullName,
      enrichment.person?.employment?.title,
      enrichment.person?.employment?.role,
      enrichment.person?.bio,
      enrichment.person?.twitter?.handle,
      enrichment.company?.name,
      enrichment.company?.description,
    ].join(' ')
  }

  @computed
  get matchTexts(): string[] {
    return [this.subject, this.contentLine, this.enrichmentMatchText].map((s) =>
      s?.toLowerCase(),
    )
  }

  tagMatch(query: string): boolean {
    const strippedTag = query.replaceAll('#', '')
    return this.contentTags.includes(strippedTag)
  }

  partialTagMatch(query: string): boolean {
    const strippedQuery = query.replaceAll('#', '')

    for (const tag of this.contentTags) {
      if (tag.includes(strippedQuery)) {
        return true
      }
    }

    return false
  }

  matchIndex(query: string): number {
    const queryLower = query.toLowerCase()
    const isTagSearch = /^#/.test(queryLower)

    if (isTagSearch) {
      return this.tagMatch(queryLower) ? 1 : -1
    }

    const matchTexts = this.matchTexts

    for (const [i, text] of matchTexts.entries()) {
      if (text?.includes(queryLower)) return matchTexts.length - i
    }

    return -1
  }

  get rootStore(): RootStore | undefined {
    return getRootStore<RootStore>(this)
  }

  @computed
  get uid(): string | undefined {
    return this.rootStore?.uid
  }

  @computed
  get canRead(): boolean {
    if (this.uid && this.aclCanRead(this.uid)) return true
    if (this.graph?.canRead) return true
    return false
  }

  @computed
  get canWrite(): boolean {
    if (this.uid && this.aclCanWrite(this.uid)) return true
    if (this.graph?.canWrite) return true
    return false
  }

  // Actions

  @action
  incrementVersion() {
    this.version += 1
  }

  @modelAction
  setSystemMetadata(value: any) {
    this.systemMetadata = value
  }

  @modelAction
  setContent(content: Node[], {rerender = false} = {}) {
    this.content = content

    if (content.length) {
      this.subject = contentSubject(this.content as SlateDocument)

      this.contentMetadata = contentMetadata(this.content as SlateDocument)
    }

    this.checkEnrichment()

    if (rerender) {
      this.incrementVersion()
    }
  }

  updateContent(content: Node[], {rerender = false, debounce = false} = {}) {
    this.setContent(content, {rerender})
    this.touch()

    if (debounce) {
      this.setRemoteDebounce()
    } else {
      this.setRemote()
    }
  }

  @modelAction
  updateSubject(subject: string) {
    this.subject = subject
    this.touch()
    this.setRemoteDebounce()
  }

  @modelAction
  updatePinned(pinned = true) {
    this.pinned = pinned
    this.touch()
    this.setRemote()
  }

  togglePinned() {
    this.updatePinned(!this.pinned)
  }

  @modelAction
  ignoreSuggestedContact(contact: Contact) {
    this.ignoredContactNames.push(contact.name)
    this.touch()
    this.setRemote()
  }

  @modelAction
  ignoreSuggestedBacklink(backlink: SuggestedBacklink) {
    this.ignoredBacklinkValues.push(backlink.value)
    this.ignoredBacklinkFromNoteIds.push(backlink.fromNoteId)
    this.touch()
    this.setRemote()
  }

  @modelAction
  incrementBacklinkedCount() {
    this.backlinkedCount += 1
    this.setRemote()
  }

  @modelAction
  rewriteBacklinks(fromNoteId: string, toNoteId: string) {
    let altered = false

    if (this.outgoingBacklinks.find((bk) => bk.toNoteId === fromNoteId)) {
      this.setContent(rewriteBacklinks(this.contentSnapshot, fromNoteId, toNoteId))
      altered = true
    }

    if (altered) {
      this.touch()
      this.setRemote()
    }
  }

  addSuggestedContact(contact: Contact) {
    this.ignoreSuggestedContact(contact)

    this.updateContent(addContactToContent(contact, this.contentSnapshot), {
      rerender: true,
    })
  }

  addMeeting({meetingNote, attendeeNotes}: {meetingNote?: Note; attendeeNotes: Note[]}) {
    this.updateContent(
      addMeetingToContent({
        content: this.contentSnapshot,
        meetingNote,
        attendeeNotes,
      }),
      {rerender: true},
    )
  }

  @modelAction
  setEnrichment(enrichment: any) {
    this.systemMetadata = {
      ...this.systemMetadata,
      enrichment,
    }
  }

  private enrichmentRequest: Promise<any> | undefined | null

  async checkEnrichment() {
    const [email] = this.contentEmails
    const [domain] = this.contentDomains

    // No emails/domains present to enrich
    if (!email && !domain) {
      if (this.enrichment) this.setEnrichment(null)
      return
    }

    const previousEmail: string | undefined = this.enrichment?.person?.email
    const previousDomain: string | undefined = this.enrichment?.company?.domain

    const emailChanged = email !== previousEmail
    const domainChanged = !previousEmail && domain !== previousDomain

    if (emailChanged || domainChanged) {
      this.setEnrichment(null)
    }

    if (!this.enrichment) {
      this.fetchEnrichment()
    }
  }

  async fetchEnrichment() {
    const [email] = this.contentEmails
    const [domain] = this.contentDomains

    if (!email && !domain) return
    if (this.enrichmentRequest) return

    this.enrichmentRequest = enrich({email, domain})
    this.setEnrichment(await this.enrichmentRequest)
    this.enrichmentRequest = null
  }

  @modelAction
  setDeletedAt(date = new Date()) {
    this.deletedAt = date
  }

  @modelAction
  restore() {
    this.deletedAt = null
  }

  deleteOrDestroy() {
    if (this.deletedAt) {
      this.destroy()
    } else {
      this.delete()
    }
  }

  delete() {
    this.noteStore?.deleteNote(this)
  }

  destroy() {
    this.noteStore?.destroyNote(this)
  }

  @modelAction
  touch() {
    return (this.updatedAt = new Date())
  }

  async setRemote() {
    // We might have already been removed from the graph
    if (this.graph) {
      return setNote(this)
    }

    return false
  }

  setRemoteDebounce = debounce(() => {
    this.setRemote()
  }, 1000)
}
