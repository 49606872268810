import firebase from 'firebase/app'
import {db} from '../firebase'
import {DocumentSnapshot} from './document-snapshot'
import {authedJsonPost, getAuthHeaders} from './helpers'
import {ApiGraph, CreateGraphResponse, SuggestGraphResponse} from './graph.types'
import {GRAPHS_COLLECTION} from './types'
import {Graph} from '../../app/models'
import {BOOK_TAG, PERSON_TAG} from '../../app/models/note/content-generators'
import {getInitialNotes} from '../../app/models/note/setup'
import {setNote} from './notes'

export const onGraphSnapshot = async (
  graphId: string,
  callback: (graphs: ApiGraph) => void,
) =>
  db
    .collection(GRAPHS_COLLECTION)
    .doc(graphId)
    .onSnapshot((snapshot) => {
      callback(convertGraph(snapshot))
    })

export const onGraphsSnapshot = async (
  graphIds: string[],
  callback: (graphs: ApiGraph[]) => void,
) =>
  db
    .collection(GRAPHS_COLLECTION)
    .where(firebase.firestore.FieldPath.documentId(), 'in', graphIds)
    .onSnapshot((snapshot) => {
      callback(snapshot.docs.map(convertGraph))
    })

export const createGraph = async ({
  name,
  id,
}: {
  name: string
  id: string
}): Promise<CreateGraphResponse> => {
  return authedJsonPost('/api/graphs', {name, id})
}

export const updateGraph = (graph: ApiGraph) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  return graphDoc.set(
    {
      name: graph.name,
      encryption_check: graph.encryptionCheck,
    },
    {merge: true},
  )
}

export const suggestGraph = async (): Promise<SuggestGraphResponse> => {
  const headers = await getAuthHeaders()
  const result = await fetch('/api/graphs/suggest', {headers})

  return result.json()
}

export const setupGraph = async (graph: Graph) => {
  if (!graph.noteStore) throw new Error('graph has no notestore')

  try {
    obtainHasSetupLock(graph)
  } catch (err) {
    console.error(err)
    return
  }

  graph.findOrCreateTag(PERSON_TAG)
  graph.findOrCreateTag(BOOK_TAG)

  const notes = Object.values(getInitialNotes(graph.assertUser))

  for (const note of notes) {
    graph.noteStore.addNote(note)
  }

  await Promise.all(notes.map((note) => setNote(note)))
}

const obtainHasSetupLock = (graph: Graph) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)

  return db.runTransaction(async (trans) => {
    const snapshot = await trans.get(graphDoc)

    if (!snapshot.exists) {
      throw new Error('unknown graph')
    } else if (snapshot.data()!.has_setup) {
      return Promise.reject('already has setup')
    } else {
      return trans.set(
        graphDoc,
        {
          has_setup: true,
        },
        {
          merge: true,
        },
      )
    }
  })
}

const convertGraph = (doc: DocumentSnapshot): ApiGraph => {
  const data = doc.data()

  return {
    id: doc.id,
    name: data?.name,
    acl: data?.acl || [],
    hasSetup: data?.has_setup ?? false,
    encryptionCheck: data?.encryption_check ?? null,
  }
}
