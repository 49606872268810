import {detach, Model, model, prop} from 'mobx-keystone'
@model('Notification')
export class Notification extends Model({
  subject: prop(''),
  description: prop(''),
  closeIn: prop<number | undefined>(),
}) {
  get id() {
    return this.$modelId
  }

  close() {
    detach(this)
  }

  onAttachedToRootStore() {
    if (this.closeIn) {
      setTimeout(() => this.close(), this.closeIn)
    }
  }
}
