import {Book} from './book'
import {onBooksSnapshot, syncBook} from '../../../services/api'
import {getParent, Model, model, modelAction, prop} from 'mobx-keystone'
import {autorun, computed} from 'mobx'
import {Graph} from '../graph/graph'
@model('BookStore')
export class BookStore extends Model({
  books: prop<Book[]>(() => []),
}) {
  get graph(): Graph | undefined {
    return getParent<Graph>(this)
  }

  get assertGraph(): Graph {
    const graph = this.graph
    if (!graph) throw new Error('blank graph')
    return graph
  }

  @computed
  get orderedBooks(): Book[] {
    return this.books.slice().sort((a, b) => b.purchaseTimestamp - a.purchaseTimestamp)
  }

  @computed
  get unsyncedBooks(): Book[] {
    return this.books.filter((book) => !book.synced)
  }

  @modelAction
  replaceBooks(books: Book[]) {
    this.books = books
  }

  findByAsin(asin: string) {
    return this.books.find((book) => book.asin === asin)
  }

  private listeners: any[] = []

  onAttachedToRootStore() {
    const graph = this.assertGraph

    this.listeners.push(
      onBooksSnapshot(graph, (books) => {
        this.replaceBooks(books)
      }),
    )

    this.listeners.push(
      autorun(
        () => {
          this.unsyncedBooks.forEach((book) => syncBook(book, graph))
        },
        {delay: 3000},
      ),
    )

    return async () => await this.beforeDetach()
  }

  async beforeDetach() {
    for (const unsubscribe of this.listeners) {
      ;(await Promise.resolve(unsubscribe))()
    }
  }
}
