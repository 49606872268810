import {Model, model, modelAction, prop} from 'mobx-keystone'
import {Notification} from './notification'
@model('notification')
export class NotificationStore extends Model({
  notifications: prop<Notification[]>(() => []),
}) {
  @modelAction
  create({
    subject,
    description,
    closeIn,
  }: {
    subject?: string
    description?: string
    closeIn?: number
  }) {
    this.notifications.push(
      new Notification({
        subject,
        description,
        closeIn,
      }),
    )
  }
}
