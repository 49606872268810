import React from 'react'
import clsx from 'classnames'
import {Node, Transforms} from 'slate'
import {ReactEditor, useEditor} from 'slate-react'
import {ELEMENT_UL} from '@udecode/slate-plugins'
import {ExpandableListItemElementProps} from './types'

export const ExpandableListItemElement: React.FC<ExpandableListItemElementProps> = ({
  element,
  attributes,
  children,
}) => {
  const editor = useEditor()
  const elementChildren: Node[] = (element.children as Node[]) || []
  const hasSubList = elementChildren.find((c) => c.type === ELEMENT_UL)

  const toggleParentCollapsed = () => {
    const path = ReactEditor.findPath(editor, element)

    if (!hasSubList) return

    Transforms.setNodes(
      editor,
      {collapsed: !element.collapsed},
      {
        at: path,
      },
    )
  }

  return (
    <li
      {...attributes}
      className={clsx('expandable-list-item list-none relative', {
        'expandable-list-item-collapsed': element.collapsed,
      })}
    >
      <span contentEditable={false} className="expandable-list-item-handle select-none">
        <span
          style={{top: 10, left: 6, width: 5, height: 5}}
          className={clsx('absolute rounded-full block bg-gray-300 transition', {
            'bg-gray-500': hasSubList && element.collapsed,
            'hover:bg-purple-550 cursor-pointer': hasSubList,
          })}
          onClick={() => toggleParentCollapsed()}
        ></span>
      </span>

      {children}
    </li>
  )
}
