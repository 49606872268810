import {jsonPost} from '../../plugins/json-post'
import {db, auth, AuthUser} from '../firebase'
import {USERS_COLLECTION} from './types'

let authUser: AuthUser | null = null

auth.onAuthStateChanged((result) => {
  authUser = result
})

export const onAuthStateChanged = (callback: (user: AuthUser | null) => void) => {
  return auth.onAuthStateChanged(callback)
}

const getResolvedRemoteAuthUser = () => {
  return new Promise<AuthUser>((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        unsubscribe()
        resolve(user)
      }
    })
  })
}

export const getAuthUser = () => {
  return new Promise<AuthUser>((resolve) => {
    if (authUser) {
      resolve(authUser)
    } else {
      getResolvedRemoteAuthUser().then((user) => {
        authUser = user
        resolve(user)
      })
    }
  })
}

export const getAuthUserDoc = async () => {
  const authUser = await getAuthUser()
  return db.collection(USERS_COLLECTION).doc(authUser.uid)
}

export const getIdToken = async () => {
  const authUser = await getAuthUser()
  return authUser.getIdToken()
}

export const getAuthHeaders = async () => {
  const idToken = await getIdToken()

  return {
    Authorization: `Firebase ${idToken}`,
  }
}

export const authedJsonPost = async (url: string, params = {}) => {
  const headers = await getAuthHeaders()

  return jsonPost(url, params, headers)
}

export const getGraphCollection = (graphId: string) => {
  return db.collection('graphs').doc(graphId)
}

export const getTagsCollection = (graphId: string) => {
  return getGraphCollection(graphId).collection('tags')
}

export function buildModel<T>(model: {new (props: any): T}, props: any): T {
  return new model({
    ...props,
    $modelId: props.$modelId || props.id,
  })
}
