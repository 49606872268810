import {setDefaults} from '@udecode/slate-plugins'
import {SlatePlugin} from '@udecode/slate-plugins-core'
import {DEFAULTS_TAG} from './defaults'
import {deserializeTag} from './deserialize-tag'
import {renderElementTag} from './render-element-tag'
import {TagPluginOptions} from './types'

/**
 * Enables support for autocompleting @tags and #tags.
 * When typing a configurable marker, such as @ or #, a select
 * component appears with autocompleted suggestions.
 */
export const TagPlugin = (options?: TagPluginOptions): SlatePlugin => {
  const {tag} = setDefaults(options, DEFAULTS_TAG)

  return {
    renderElement: renderElementTag(options),
    deserialize: deserializeTag(options),
    inlineTypes: [tag.type],
    voidTypes: [tag.type],
  }
}
