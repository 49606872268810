import {SlatePlugin} from '@udecode/slate-plugins-core'
import {onKeyDownBreakList} from './on-key-down-break-list'
import {BreakListPluginOptions} from './types'

/**
 * Enables support for resetting block type from rules.
 */
export const BreakListPlugin = (options: BreakListPluginOptions): SlatePlugin => ({
  onKeyDown: onKeyDownBreakList(options),
})
