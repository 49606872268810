import {Model, model, prop} from 'mobx-keystone'
import {computed} from 'mobx'
import {uniq} from 'lodash'
import {Email} from './email'
import {PhoneNumber} from './phone-number'
@model('Contact')
export class Contact extends Model({
  givenName: prop<string>(''),
  familyName: prop<string>(''),
  emails: prop<Email[]>(() => []),
  phoneNumbers: prop<PhoneNumber[]>(() => []),
  photoUrl: prop<string | undefined>(),
}) {
  get id() {
    return this.$modelId
  }

  @computed
  get name() {
    return [this.givenName, this.familyName].filter((n) => n).join(' ')
  }

  @computed
  get emailValues() {
    const values = this.emails
      .slice()
      .sort((a, b) => (a.primary ? -1 : 1) - (b.primary ? -1 : 1))
      .map((e) => e.value?.toLowerCase())

    return uniq(values)
  }

  @computed
  get primaryEmail(): string | undefined {
    const email = this.emails.find((email) => email.primary) || this.emails[0]

    return email?.value
  }

  @computed
  get phoneNumberValues() {
    const values = this.phoneNumbers
      .slice()
      .sort((a, b) => (a.primary ? -1 : 1) - (b.primary ? -1 : 1))
      .map((e) => e.value)

    return uniq(values)
  }

  @computed
  get validName() {
    return /^[A-Z]\w+\s[A-Z]\w+/.test(this.name)
  }

  static build({name, email}: {name?: string; email?: string}): Contact {
    const [givenName, familyName] = name?.split(' ', 2) || []

    const emails: Email[] = email ? [{value: email, primary: true}] : []

    return new Contact({
      givenName,
      familyName,
      emails,
    })
  }
}
