const validResponseStatusCodes = [200, 201]

export const jsonPost = async (url: string, params = {}, headers = {}) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params),
  })

  if (!validResponseStatusCodes.includes(response.status)) {
    throw new Error('Invalid response: ' + response)
  }

  return response.json()
}
