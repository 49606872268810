/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_LI,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  setDefaults,
} from '@udecode/slate-plugins'
import markdown from 'remark-parse'
import unified from 'unified'
import {wikiLinkPlugin} from 'remark-wiki-link'
import {ELEMENT_BACKLINK} from '../../plugins/backlink'
import {toId} from '../../../../plugins/to-id'

export interface NodeTypes {
  paragraph?: string
  block_quote?: string
  code_block?: string
  link?: string
  ul_list?: string
  ol_list?: string
  listItem?: string
  backlink?: string
  heading?: {
    1?: string
    2?: string
    3?: string
    4?: string
    5?: string
    6?: string
  }
}

export interface OptionType {
  nodeTypes: NodeTypes
}

export interface MdastNode {
  type?: string
  ordered?: boolean
  value?: string
  text?: string
  children?: Array<MdastNode>
  depth?: 1 | 2 | 3 | 4 | 5 | 6
  url?: string
  lang?: string
  // mdast metadata
  position?: any
  spread?: any
  checked?: any
  indent?: any
}

export const defaultNodeTypes = {
  paragraph: 'p',
  block_quote: 'blockquote',
  code_block: 'code',
  link: 'link',
  ul_list: 'ul',
  ol_list: 'ol',
  listItem: 'li',
  backlink: 'backlink',
  heading: {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  },
}

const transform = (node: MdastNode, opts: OptionType = {nodeTypes: {}}) => {
  const types = {
    ...defaultNodeTypes,
    ...opts.nodeTypes,
    heading: {
      ...defaultNodeTypes.heading,
      ...opts?.nodeTypes?.heading,
    },
  }

  let children = [{text: ''}]

  if (node.children && Array.isArray(node.children) && node.children.length > 0) {
    // @ts-ignore
    children = node.children.map((c: MdastNode) =>
      transform(
        {
          ...c,
          ordered: node.ordered || false,
        },
        opts,
      ),
    )
  }

  switch (node.type) {
    case 'heading':
      return {type: types.paragraph, children}
    case 'list':
      return {type: types.paragraph, children}
    case 'listItem':
      return {text: node.value || ''}
    case 'paragraph':
      return {type: types.paragraph, children}
    case 'link':
      // @maccman - changed links to have `url` properties
      return {type: types.link, url: node.url, children}
    case 'blockquote':
      return {type: types.block_quote, children}
    case 'wikiLink':
      return {
        type: types.backlink,
        value: node.value,
        noteId: toId(node.value!),
        children: [{text: ''}],
      }
    case 'code':
      return {type: types.paragraph, children}

    case 'html':
      return {type: 'paragraph', children: [{text: ''}]}

    case 'emphasis':
      return {
        italic: true,
        ...forceLeafNode(children),
        ...persistLeafFormats(children),
      }
    case 'strong':
      return {
        bold: true,
        ...forceLeafNode(children),
        ...persistLeafFormats(children),
      }
    case 'delete':
      return {
        strikeThrough: true,
        ...forceLeafNode(children),
        ...persistLeafFormats(children),
      }

    case 'text':
    default:
      return {text: node.value || ''}
  }
}

const forceLeafNode = (children: Array<{text?: string}>) => ({
  text: children.map((k) => k?.text).join(''),
})

// This function is will take any unknown keys, and bring them up a level
// allowing leaf nodes to have many different formats at once
// for example, bold and italic on the same node
function persistLeafFormats(children: Array<MdastNode>) {
  return children.reduce((acc, node) => {
    Object.keys(node).forEach(function (key) {
      if (key === 'children' || key === 'type' || key === 'text') return

      // @ts-ignore
      acc[key] = node[key]
    })

    return acc
  }, {})
}

function plugin(opts?: OptionType) {
  const compiler = (node: {children: Array<MdastNode>}) => {
    return node.children.map((c) => transform(c, opts))
  }

  // @ts-ignore
  this.Compiler = compiler
}

export const deserialize = (options?: Record<string, any>) => (content: string) => {
  const {p, blockquote, link, ul, ol, li, h1, h2, h3, h4, h5, h6, backlink} = setDefaults(
    options,
    {
      p: {type: ELEMENT_PARAGRAPH},
      blockquote: {type: ELEMENT_BLOCKQUOTE},
      link: {type: ELEMENT_LINK},
      ul: {type: ELEMENT_UL},
      ol: {type: ELEMENT_OL},
      li: {type: ELEMENT_LI},
      h1: {type: ELEMENT_H1},
      h2: {type: ELEMENT_H2},
      h3: {type: ELEMENT_H3},
      h4: {type: ELEMENT_H4},
      h5: {type: ELEMENT_H5},
      h6: {type: ELEMENT_H6},
      backlink: {type: ELEMENT_BACKLINK},
    },
  )

  const tree: any = unified()
    .use(markdown)
    .use(wikiLinkPlugin)
    .use(plugin, {
      nodeTypes: {
        paragraph: p.type,
        block_quote: blockquote.type,
        link: link.type,
        ul_list: ul.type,
        ol_list: ol.type,
        listItem: li.type,
        backlink: backlink.type,
        heading: {
          1: h1.type,
          2: h2.type,
          3: h3.type,
          4: h4.type,
          5: h5.type,
          6: h6.type,
        },
      },
    })
    .processSync(content)

  return tree.result
}
