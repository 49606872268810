/* eslint-disable no-case-declarations */
import {clone} from 'mobx-keystone'
import {Graph, Note} from '../../app/models'
import {unparse} from 'papaparse'

export const exportNotes = (graph: Graph, format: ExportFormat) => {
  const type = CONTENT_TYPES[format]
  const ext = CONTENT_EXTENSIONS[format]

  const notes = clone(graph.assertNoteStore.notes)
  const notesExport = notes.map((note) => convertNoteToExport(note))

  const data = generateExport(notesExport, format)
  const blob = new Blob([data], {type})
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.download = `export${ext}`
  link.href = url
  link.click()
}

export type ExportFormat = 'json' | 'csv'

const CONTENT_TYPES = {
  json: 'application/json',
  csv: 'text/csv',
}

const CONTENT_EXTENSIONS = {
  json: '.json',
  csv: '.csv',
}

const generateExport = (data: any, format: ExportFormat) => {
  switch (format) {
    case 'json':
      return JSON.stringify(data, null, 2)
    case 'csv':
      return unparse(data)
  }
}

const convertNoteToExport = (note: Note) => {
  return {
    id: note.id,
    subject: note.subject,
    content_html: note.contentHtml,
    content_markdown: note.contentMarkdown,
    created_at: note.createdAt,
    updated_at: note.updatedAt,
  }
}
