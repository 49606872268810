import {getTagsCollection} from './helpers'

export const onTagsSnapshot = async (
  graphId: string,
  callback: (tags: string[]) => void,
) => {
  const collection = getTagsCollection(graphId)

  return collection.onSnapshot((snapshot) => {
    callback(snapshot.docs.map((doc) => doc.id))
  })
}

export const createTag = (graphId: string, tag: string) => {
  const collection = getTagsCollection(graphId)
  return collection.doc(tag).set({created_at: new Date()})
}
