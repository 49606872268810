import {getAuthUser, getAuthUserDoc} from './helpers'
import {User as AuthUser} from '@firebase/auth-types'
import {DocumentSnapshot} from './document-snapshot'
import {auth, db, storage} from '../firebase'
import {APIUser, APIUserTraits, USERS_COLLECTION} from './types'

export const onUserSnapshot = async (callback: (arg0: APIUser) => void) => {
  const authUser = await getAuthUser()
  const doc = await getAuthUserDoc()

  return doc.onSnapshot((snapshot) => {
    callback(hydrateWithAuthUser(convertUser(snapshot), authUser))
  })
}

export const setTraits = async (userId: string, traits: any) => {
  const doc = db.collection(USERS_COLLECTION).doc(userId)
  await doc.set({traits}, {merge: true})
}

export const setTraitsForAuthUser = async (traits: any) => {
  const doc = await getAuthUserDoc()
  await doc.set({traits}, {merge: true})
}

export const updatePhoto = async (file: File) => {
  const authUser = await getAuthUser()
  const stringRef = `users/${authUser.uid}/photo`
  const storageRef = storage.ref(stringRef)

  await storageRef.put(file, {
    contentType: file.type,
  })

  const photoURL = await storageRef.getDownloadURL()

  await auth.currentUser?.updateProfile({photoURL})

  return photoURL
}

// Private

const hydrateWithAuthUser = (user: APIUser, authUser: AuthUser) => {
  if (!authUser) {
    return user
  } else {
    return {
      ...user,
      id: authUser.uid,
      name: authUser.displayName,
      email: authUser.email,
      photoUrl: authUser.photoURL,
    } as APIUser
  }
}

const convertTraits = (traits: any): APIUserTraits => {
  const result: APIUserTraits = {}

  for (const key of Object.keys(traits)) {
    const value = traits[key]

    if (typeof value?.toDate === 'function') {
      result[key] = value.toDate().toISOString()
    } else {
      result[key] = value
    }
  }

  return result
}

const convertUser = (doc: DocumentSnapshot) => {
  const data = doc.data()

  // Firebase functions set's an id when the user is ready
  const loaded = typeof data?.id === 'string'

  return {
    id: doc.id,
    loaded,
    gatedAccess: data?.gated_access === true,
    graphIds: data?.graph_ids || [],
    traits: convertTraits(data?.traits || {}),
    stripeSubscriptionId: data?.stripe_subscription_id,
    stripeSubscriptionProductId: data?.stripe_subscription_product_id,
  } as APIUser
}
