import {isCollapsed, isPointAtWordEnd, isWordAfterTrigger} from '@udecode/slate-plugins'
import {useCallback, useState} from 'react'
import {Editor, Range, Transforms} from 'slate'
import {insertTag} from './transforms'
import {TagNodeData, UseTagOptions} from './types'
import {getNextIndex, getPreviousIndex} from './utils'

export const useTag = (
  mentionables: TagNodeData[] = [],
  {maxSuggestions = 7, onAddTag, ...options}: UseTagOptions = {},
) => {
  const trigger = '#'
  const [targetRange, setTargetRange] = useState<Range | null>(null)
  const [valueIndex, setValueIndex] = useState(0)
  const [search, setSearch] = useState('')
  const values = mentionables
    .filter((c) => c.value.toLowerCase().includes(search.toLowerCase()))
    .slice(0, maxSuggestions)

  const addTag = useCallback(
    (editor: Editor, tag: TagNodeData) => {
      if (targetRange !== null) {
        const hydratedTag = onAddTag?.(tag) || tag
        Transforms.select(editor, targetRange)
        insertTag(editor, hydratedTag, options)
        setTargetRange(null)
      }
    },
    [options, targetRange],
  )

  const onKeyDown = useCallback(
    (e: any, editor: Editor) => {
      if (targetRange) {
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          return setValueIndex(getNextIndex(valueIndex, values.length))
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault()
          return setValueIndex(getPreviousIndex(valueIndex, values.length))
        }
        if (e.key === 'Escape') {
          e.preventDefault()
          return setTargetRange(null)
        }

        if (['Tab', 'Enter'].includes(e.key)) {
          e.preventDefault()
          addTag(editor, values[valueIndex] || {value: search})
          return false
        }
      }
    },
    [values, valueIndex, setValueIndex, targetRange, setTargetRange, addTag],
  )

  const toTag = (value: string) =>
    value.toLowerCase().trim().replace(/^.+-/g, '').replace(/[\W_]/g, '')

  const onChange = useCallback(
    (editor: Editor) => {
      const {selection} = editor

      if (selection && isCollapsed(selection)) {
        const cursor = Range.start(selection)

        const {range, match: beforeMatch} = isWordAfterTrigger(editor, {
          at: cursor,
          trigger,
        })

        if (beforeMatch && isPointAtWordEnd(editor, {at: cursor})) {
          setTargetRange(range as Range)
          const [, word] = beforeMatch
          setSearch(toTag(word))
          setValueIndex(0)
          return
        }
      }

      setTargetRange(null)
    },
    [setTargetRange, setSearch, setValueIndex],
  )

  return {
    search,
    index: valueIndex,
    target: targetRange,
    values,
    onChange,
    onKeyDown,
    addTag,
  }
}
