import * as React from 'react'
import {classNamesFunction, styled} from '@uifabric/utilities'
import {useFocused, useSelected} from 'slate-react'
import {TagElementProps, TagElementStyleProps, TagElementStyles} from '../types'
import {getTagElementStyles} from './tag-element.styles'
import {useNoteStore} from '../../../../../app/models/store/context'
import {useHotkeys} from 'react-hotkeys-hook'

const getClassNames = classNamesFunction<TagElementStyleProps, TagElementStyles>()

/**
 *   TagElement with no default styles.
 * [Use the `styles` API to add your own styles.](https://github.com/OfficeDev/office-ui-fabric-react/wiki/Component-Styling)
 */
export const TagElementBase = ({
  attributes,
  children,
  element,
  className,
  styles,
  as: Tag = 'span',
}: TagElementProps) => {
  const selected = useSelected()
  const focused = useFocused()

  const classNames = getClassNames(styles, {
    className,
    // Other style props
    selected,
    focused,
  })

  const noteStore = useNoteStore()

  const openTag = () => {
    noteStore?.setAndOpenSearchQuery(`#${element.value}`)
  }

  useHotkeys(
    'enter',
    (event) => {
      if (selected) {
        event.preventDefault()
        openTag()
      }
    },
    [element.value, selected],
  )

  return (
    <Tag
      {...attributes}
      data-slate-value={element.value}
      contentEditable={false}
      className={classNames.root}
      onClick={openTag}
    >
      #{element.value}
      {children}
    </Tag>
  )
}

/**
 * TagElement
 */
export const TagElement = styled<TagElementProps, TagElementStyleProps, TagElementStyles>(
  TagElementBase,
  getTagElementStyles,
  undefined,
  {
    scope: 'TagElement',
  },
)
