import {setDefaults} from '@udecode/slate-plugins'
import {Editor, Transforms} from 'slate'
import {DEFAULTS_BACKLINK} from '../defaults'
import {BacklinkNode, BacklinkNodeData, BacklinkPluginOptions} from '../types'

export const insertBacklink = (
  editor: Editor,
  backlinkable: BacklinkNodeData,
  options?: BacklinkPluginOptions,
) => {
  const {backlink} = setDefaults(options, DEFAULTS_BACKLINK)

  const backlinkNode: BacklinkNode = {
    type: backlink.type,
    children: [{text: ''}],
    ...backlinkable,
  }
  Transforms.insertNodes(editor, backlinkNode)
  Transforms.move(editor)
}
