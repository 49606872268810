import {getRootStore, Model, model, prop, prop_dateString} from 'mobx-keystone'
import {Contact} from '../contact/contact'
import {RootStore} from '../store/root-store'
import {MeetingAttendee} from './meeting-attendee'

@model('Meeting')
export class Meeting extends Model({
  name: prop<string | undefined>(),
  description: prop<string | undefined>(),
  startAt: prop_dateString<Date | undefined>(),
  endAt: prop_dateString<Date | undefined>(),
  recurring: prop<boolean>(false),
  attendees: prop<MeetingAttendee[]>(() => []),
}) {
  get id() {
    return this.$modelId
  }

  get rootStore() {
    return getRootStore<RootStore>(this)
  }

  get contactStore() {
    return this.rootStore?.contactStore
  }

  get contacts() {
    const result: Contact[] = []

    for (const attendee of this.attendees) {
      if (!attendee.email) continue

      const contact = this.contactStore?.findByEmail(attendee.email)

      if (contact) {
        result.push(contact)
      } else if (attendee.name) {
        result.push(
          Contact.build({
            name: attendee.name,
            email: attendee.email,
          }),
        )
      }
    }

    return result
  }
}
