import {computed} from 'mobx'
import {Model, model, modelAction, prop} from 'mobx-keystone'
import {onUserSnapshot, APIAuthUser, buildModel} from '../../../services/api'
import {onAuthStateChanged} from '../../../services/api'
import {User} from './user'
@model('UserStore')
export class UserStore extends Model({
  currentUser: prop<User | undefined | null>(),
  authUserReceived: prop(false),
  authUserUID: prop<string | undefined | null>(),
}) {
  get id() {
    return this.$modelId
  }

  @computed
  get needsAuth() {
    return !this.authUserUID && this.authUserReceived
  }

  @computed
  get signedIn() {
    return !this.needsAuth
  }

  @modelAction
  setCurrentUser(user: User | null) {
    this.currentUser = user
  }

  @modelAction
  setAuthUser(authUser: APIAuthUser | null) {
    this.authUserUID = authUser?.uid
    this.authUserReceived = true
  }

  @modelAction
  reset() {
    this.authUserUID = null
  }

  private listeners: any[] = []

  onAttachedToRootStore() {
    this.listeners.push(
      onAuthStateChanged((authUser) => {
        this.setAuthUser(authUser)
      }),
    )

    this.listeners.push(
      onUserSnapshot((snapshot) => {
        this.setCurrentUser(buildModel<User>(User, snapshot))
      }),
    )

    return async () => await this.beforeDetach()
  }

  async beforeDetach() {
    for (const unsubscribe of this.listeners) {
      ;(await Promise.resolve(unsubscribe))()
    }
  }
}
