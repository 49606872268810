import {format} from 'date-fns'
import {decrypt, encrypt, hash} from 'kiss-crypto'
import {getSnapshot} from 'mobx-keystone'
import {DbNote, DbNoteEncrypted, DbNoteUnencrypted, NoteChange} from './notes.types'
import {buildModel, getAuthUser} from './helpers'
import {db} from '../firebase'
import {DocumentChange} from './document-change'
import {generateNoteWithList} from '../../app/models/note/content-generators'
import {Graph, Note} from '../../app/models'
import {DocumentSnapshot} from './document-snapshot'
import {GRAPHS_COLLECTION, NOTES_COLLECTION} from './types'
import {chunk} from 'lodash'

export const createDailyNote = async (graph: Graph, date = new Date()) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)

  const noteId = format(date, 'ddMMyyyy')
  const subject = format(date, 'MMMM do, yyyy')
  const content = generateNoteWithList(subject)
  const query = graphDoc.collection(NOTES_COLLECTION).doc(noteId)

  const note = new Note({
    $modelId: noteId,
    content,
    subject,
    daily: true,
    createdAt: date,
    updatedAt: date,
  })

  await db.runTransaction(async (trans) => {
    const existingNote = await trans.get(query)

    if (!existingNote.exists) {
      const dbNote = await convertToDbNote(note, graph.encryptionKey)

      return trans.set(query, dbNote, {merge: true})
    }
  })
}

export const batchCreateNotes = async (notes: Note[], graph: Graph) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)

  const dbNotes = await Promise.all(
    notes.map((note) => convertToDbNote(note, graph.encryptionKey)),
  )

  for (const dbNotesChunk of chunk(dbNotes, BATCH_LIMIT)) {
    const batch = db.batch()

    for (const dbNote of dbNotesChunk) {
      const noteDoc = graphDoc.collection(NOTES_COLLECTION).doc(dbNote.id)

      batch.set(noteDoc, dbNote, {merge: true})
    }

    await batch.commit()
  }
}

export const setNote = async (note: Note, graph = note.graph) => {
  if (!graph) throw new Error('blank graph')

  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  const noteDoc = graphDoc.collection(NOTES_COLLECTION).doc(note.id)
  const dbNote = await convertToDbNote(note, graph.encryptionKey)

  return noteDoc.set(dbNote, {merge: true})
}

export const deleteNote = async (graphId: string, noteId: string) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graphId)
  const noteDoc = graphDoc.collection(NOTES_COLLECTION).doc(noteId)
  return noteDoc.delete()
}

export const onReadonlyNotesSnapshot = async (
  graph: Graph,
  callback: (snapshots: NoteChange[]) => void,
) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  const authUser = await getAuthUser()

  const notesDoc = graphDoc
    .collection(NOTES_COLLECTION)
    .where('acl', 'array-contains-any', [
      'public',
      `read.${authUser.uid}`,
      `write.${authUser.uid}`,
      `owner.${authUser.uid}`,
    ])

  console.log(`[${graph.id}]`, 'Setting up readonly notes snapshot listener')

  return notesDoc.onSnapshot((snapshot) => {
    const docChanges = snapshot.docChanges()
    const noteChanges = docChanges.map((change) => convertToNoteChange(graph, change))
    callback(noteChanges)
  })
}

export const onNotesSnapshot = async (
  graph: Graph,
  callback: (snapshots: NoteChange[]) => void,
) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  const notesDoc = graphDoc.collection(NOTES_COLLECTION)

  console.log(`[${graph.id}]`, 'Setting up notes snapshot listener')

  return notesDoc.onSnapshot((snapshot) => {
    const docChanges = snapshot.docChanges()
    const noteChanges = docChanges.map((change) => convertToNoteChange(graph, change))
    callback(noteChanges)
  })
}

export const listNotes = async (graph: Graph) => {
  const graphDoc = db.collection(GRAPHS_COLLECTION).doc(graph.id)
  const notesDoc = graphDoc.collection(NOTES_COLLECTION)
  const notesDocSnapshots = (await notesDoc.get()).docs
  const notes = notesDocSnapshots.map((note) =>
    convertFromDbNote(note, graph.encryptionKey),
  )

  return Promise.all(notes)
}

export const reEncryptNotes = async (notes: Note[], graph: Graph) => {
  return batchCreateNotes(notes, graph)
}

// Helpers

const BATCH_LIMIT = 500

const deriveNoteEncryptionKey = async (noteId: string, encryptionKey: string) => {
  return await hash({
    key: encryptionKey,
    salt: noteId,
  })
}

// Convertors

const NOTE_VERSION = 2

const convertToNoteChange = (graph: Graph, change: DocumentChange): NoteChange => {
  const data = change.doc.data()

  return {
    id: change.doc.id,
    type: change.type,
    createdAt: data?.created_at?.toDate(),
    updatedAt: data?.updated_at?.toDate(),
    get note() {
      return convertFromDbNote(change.doc, graph.encryptionKey)
    },
  }
}

const convertToDbNote = async (
  note: Note,
  encryptionKey?: string | null,
): Promise<DbNote> => {
  const values = getSnapshot(note)
  const valuesJson = JSON.stringify(values)

  let dbNote: DbNoteEncrypted | DbNoteUnencrypted

  if (encryptionKey) {
    const noteEncryptionKey = await deriveNoteEncryptionKey(note.id, encryptionKey)

    dbNote = {
      id: note.id,
      version: NOTE_VERSION,
      acl: [],
      created_at: note.createdAt,
      updated_at: note.updatedAt,
      encrypted_note_json: await encrypt({
        key: noteEncryptionKey,
        plaintext: valuesJson,
      }),
      note_json: null,
    } as DbNoteEncrypted
  } else {
    dbNote = {
      id: note.id,
      version: NOTE_VERSION,
      acl: [],
      created_at: note.createdAt,
      updated_at: note.updatedAt,
      encrypted_note_json: null,
      note_json: valuesJson,
    } as DbNoteUnencrypted
  }

  return dbNote
}

const convertFromDbNote = async (
  doc: DocumentSnapshot,
  encryptionKey?: string | null,
): Promise<Note> => {
  const data = doc.data()!

  let valuesJson: string | null = null

  if (data.encrypted_note_json) {
    if (!encryptionKey) {
      throw new Error('note is encrypted, but no key was supplied')
    }

    const noteEncryptionKey = await deriveNoteEncryptionKey(doc.id, encryptionKey)

    valuesJson = await decrypt({
      key: noteEncryptionKey,
      ciphertext: data.encrypted_note_json,
    })
  } else if (data.note_json) {
    valuesJson = data.note_json
  }

  if (!valuesJson) {
    console.error('Malformed note', data)
    throw new Error('Malformed note')
  }

  const values = JSON.parse(valuesJson)
  return buildModel<Note>(Note, values)
}
