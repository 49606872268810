import {generateId} from '../../plugins/generate-id'
import {electron} from '../electron'
import {db, auth} from '../firebase'
import {getIdToken} from './helpers'

export const startAuthProcess = () => {
  const clientToken = generateId()

  const unsubscribe = db
    .collection('client-tokens')
    .doc(clientToken)
    .onSnapshot(async (snapshot) => {
      if (!snapshot?.exists) return

      unsubscribe()

      const accessToken: string = snapshot.data()?.access_token

      if (accessToken) {
        await auth.signInWithCustomToken(accessToken)
        electron?.showWindow()
      }
    })

  return clientToken
}

export const createAccessToken = async (clientToken: string) => {
  const idToken = await getIdToken()

  return fetch('/api/auth/client-token', {
    method: 'POST',
    headers: {
      Authorization: `Firebase ${idToken}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      client_token: clientToken,
      id_token: idToken,
    }),
  })
}
