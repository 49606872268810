import {autorun} from 'mobx'
import {applySnapshot, detach, getSnapshot} from 'mobx-keystone'
import {NoteChange, onNotesSnapshot, onReadonlyNotesSnapshot} from '../../../services/api'
import {Note} from './note'
import {NoteStore} from './note-store'
import {log} from '../../../plugins/log'

export const setupSnapshotsListener = (store: NoteStore) => {
  let unsubscribeSnapshot: Promise<() => void> | undefined

  const unsubscribeAutorun = autorun(async () => {
    // Ensure graph & user is attached
    if (!store.graph?.loaded) return

    // Does the user have read permissions over the graph?
    if (store.graph.canRead) {
      log(`[${store.graph.id}]`, 'Loading notes')
      unsubscribeSnapshot = onNotesSnapshot(store.graph, (changes) => {
        applyChanges(store, changes)
      })

      // Or perhaps there are some public notes?
    } else {
      log(`[${store.graph.id}]`, 'Loading readonly notes')
      unsubscribeSnapshot = onReadonlyNotesSnapshot(store.graph, (changes) => {
        applyChanges(store, changes)
      })
    }

    // Only run once
    unsubscribeAutorun()
  })

  return async () => {
    unsubscribeAutorun()

    if (unsubscribeSnapshot) {
      ;(await Promise.resolve(unsubscribeSnapshot))()
    }
  }
}

// Helpers

const dateEqual = (dateA: Date | null, dateB: Date | null) => {
  return dateA?.getTime() === dateB?.getTime()
}

const applyMergedSnapshot = (note: Note, noteToApply: Note) => {
  const noteToApplySnapshot = getSnapshot(noteToApply)

  const mergedSnapshot = {
    ...noteToApplySnapshot,
    $modelId: note.$modelId,
    $modelType: note.$modelType,
  }

  applySnapshot(note, mergedSnapshot)

  // Force a re-render
  note.incrementVersion()
}

// Apply note changes

const applyChange = async (store: NoteStore, change: NoteChange) => {
  const localNote = store.findById(change.id)

  let localNoteIdentical = false
  let localNoteNewer = false

  if (localNote) {
    localNoteIdentical = dateEqual(localNote.updatedAt, change.updatedAt)

    localNoteNewer = localNote.updatedAt >= change.updatedAt
  }

  switch (change.type) {
    case 'added':
      // Either a note just got created, or a change
      // has just been subscribed to.
      if (localNote) {
        if (!localNoteIdentical) {
          applyMergedSnapshot(localNote, await change.note)
        }
      } else {
        store.addNote(await change.note)
      }

      break
    case 'modified':
      // A note was changed. First check if we have newer local changes.
      // Otherwise, merge in the newer note.
      if (localNoteIdentical || localNoteNewer) {
        return
      } else if (localNote) {
        applyMergedSnapshot(localNote, await change.note)
      } else {
        store.addNote(await change.note)
      }

      break
    case 'removed':
      if (localNote) {
        detach(localNote)
      }

      break
  }
}

export const applyChanges = async (store: NoteStore, changes: NoteChange[]) => {
  log(`[${store.graphId}]`, `Loading in ${changes.length} changes`)

  for (const change of changes) {
    await applyChange(store, change)
  }
}
