import {Model, model, modelAction, prop, Ref, rootRef} from 'mobx-keystone'
import {Graph} from '../graph/graph'

export type PreferenceView = 'profile' | 'credentials' | 'billing' | 'graph'
export type PreferenceTheme = 'system' | 'dark' | 'light'

const graphRef = rootRef<Graph>('GraphRef')
@model('PreferenceStore')
export class PreferenceStore extends Model({
  view: prop<PreferenceView>('profile'),
  theme: prop<PreferenceTheme>('system'),
  downloadPrompt: prop(true),
  shortcutPrompt: prop(true),
  selectedGraph: prop<Ref<Graph> | undefined>(),
}) {
  @modelAction
  setView(view: PreferenceView) {
    this.view = view
  }

  @modelAction
  setTheme(theme: PreferenceTheme) {
    this.theme = theme
  }

  @modelAction
  setSelectedGraph(graph: Graph) {
    this.selectedGraph = graphRef(graph)
  }

  @modelAction
  hideDownloadPrompt() {
    this.downloadPrompt = false
  }

  @modelAction
  hideShortcutPrompt() {
    this.shortcutPrompt = false
  }
}
