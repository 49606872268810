import {getAuthUser, setTraits} from './api'
import {auth, analytics as firebaseAnalytics} from './firebase'

const browser = typeof window != 'undefined'

export const analytics = browser ? window.analytics : undefined

export const useAnalytics = () => {
  auth.onIdTokenChanged((user) => {
    if (user?.email) {
      setAuthUser({
        uid: user.uid,
        email: user.email,
        name: user.displayName,
      })
    }
  })
}

export const identify = async (traits: any, userId?: string) => {
  userId = userId || (await getUserId())

  setTraits(userId, traits)
  analytics?.identify(userId, traits)
}

// Private

const getUserId = async () => {
  const authUser = await getAuthUser()
  return authUser.uid
}

const setAuthUser = ({
  uid,
  email,
  name,
}: {
  uid: string
  email: string
  name: string | null
}) => {
  const [, domain] = email.split('@', 2)

  // Firebase doesn't let you send user-identifying info (like emails)
  firebaseAnalytics?.setUserId(uid)
  firebaseAnalytics?.setUserProperties({domain: domain})

  identify(
    {
      email,
      name,
    },
    uid,
  )
}
