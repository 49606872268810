import * as React from 'react'
import {useEffect, useState} from 'react'
import {calculateBestPosition, Point} from 'calculate-position'
import {ReactEditor, useSlate} from 'slate-react'
import {TagSelectProps} from './tag-select.types'
import clsx from 'classnames'
import {PortalBody} from '../../../..'

export const TagSelect = ({
  at,
  options,
  valueIndex,
  search,
  onClickTag,
  width = 200,
  rowHeight = 36,
  ...props
}: TagSelectProps) => {
  const editor = useSlate()
  const [position, setPosition] = useState<Point>({left: -500, top: -500})
  const maxOptions = options.length
  const height = (search ? maxOptions + 1 : maxOptions) * rowHeight

  useEffect(() => {
    if (!at) return

    const domRange = ReactEditor.toDOMRange(editor, at)
    const rect = domRange.getBoundingClientRect()

    const anchor = {
      top: rect.top,
      left: rect.left,
      width: 10,
      height: 20,
    }

    const dimensions = {
      width,
      height,
    }

    const {left, top} = calculateBestPosition({
      anchor,
      dimensions,
    })

    setPosition({left, top})
  }, [options.length, editor, at])

  if (!at) {
    return null
  }

  return (
    <PortalBody>
      <div
        style={{...position}}
        className={
          'absolute z-10 rounded-md shadow-lg bg-white dark:bg-dark-black-800 min-w-52 max-w-80'
        }
        {...props}
      >
        <div className="ring-1 ring-black ring-opacity-5 rounded-md overflow-hidden">
          {options.map((option, i) => (
            <div
              key={`${i}${option.value}`}
              className={clsx(
                'flex space-x-2 px-3 py-2 text-sm leading-5  cursor-pointer hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150',
                {
                  'bg-white dark:bg-dark-black-800 text-gray-700 dark:text-near-white':
                    i !== valueIndex,
                },
                {
                  'bg-gray-100 text-gray-900 dark:bg-gray-100 dark:text-gray-900':
                    i === valueIndex,
                },
              )}
              onMouseDown={(event) => {
                event.preventDefault()
                onClickTag?.(editor, option)
              }}
            >
              <div className="truncate">#{option.value}</div>
            </div>
          ))}

          {search && (
            <div
              key="new"
              className={clsx(
                'flex space-x-1 px-3 py-2 text-sm leading-5  cursor-pointer hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150',
                {
                  'bg-white dark:bg-dark-black-800 text-gray-700 dark:text-near-white':
                    maxOptions !== valueIndex,
                },
                {
                  'bg-gray-100 text-gray-900 dark:bg-gray-100 dark:text-gray-900':
                    maxOptions === valueIndex,
                },
              )}
              onMouseDown={(event) => {
                event.preventDefault()
                onClickTag?.(editor, {value: search})
              }}
            >
              <span>Create tag</span>
              <em>#{search}</em>
            </div>
          )}
        </div>
      </div>
    </PortalBody>
  )
}
