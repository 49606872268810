import {Model, model, modelAction, prop, prop_dateTimestamp} from 'mobx-keystone'
import {computed} from 'mobx'
import {format} from 'date-fns'
import {generateCoverSrc, generateListingUrl} from './kindle'
import {BookNote} from './book-note'

@model('Book')
export class Book extends Model({
  asin: prop<string>(),
  authors: prop<string[]>(),
  publicationDate: prop_dateTimestamp<Date>(),
  purchaseDate: prop_dateTimestamp<Date>(),
  title: prop<string>(),
  notes: prop<BookNote[]>(),
  synced: prop(true),
}) {
  get id() {
    return this.$modelId
  }

  @computed
  get publicationDateFormatted() {
    return format(this.publicationDate, 'MM/dd/yyyy')
  }

  @computed
  get purchaseDateFormatted() {
    return format(this.purchaseDate, 'MM/dd/yyyy')
  }

  @computed
  get purchaseTimestamp() {
    return this.purchaseDate?.getTime()
  }

  @computed
  get coverSrc() {
    return generateCoverSrc(this.asin)
  }

  @computed
  get listingUrl() {
    return generateListingUrl(this.asin)
  }

  @modelAction
  setSynced(value: boolean) {
    this.synced = value
  }

  matchIndex(query: string) {
    const queryLower = query.toLowerCase()

    const matchTexts = [this.title, this.authors.join(' ')]
      .map((s) => s?.toLowerCase())
      .filter((s) => s)

    for (const [i, text] of matchTexts.entries()) {
      if (text?.includes(queryLower)) return matchTexts.length - i
    }

    return -1
  }
}
